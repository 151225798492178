import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import React, { useState, useEffect } from "react";
import { colors } from "../../../config/constants";

const UserLocationMarker: React.FC<{ position: any }> = (props) => {
  const [userLocation, setUserLocation] = useState(props.position);
  useEffect(() => {
    setUserLocation(props.position);
  }, [props.position]);

  return (
    <AdvancedMarker
      key={"userLocation"}
      position={userLocation}
      title="My Location"
    >
      <Pin
        background={colors.iceBlue}
        glyphColor={colors.black}
        borderColor={"#000"}
      />
    </AdvancedMarker>
  );
};

export default UserLocationMarker;
