import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchJobs = async (params = {}) => {
  const apiService = new ApiService();
  const data = await apiService.get(apiUrl + "/jobs", params);
  console.log("jobs fetched ", data.data.length);
  return data;
};

export const fetchSoldJobs = async (params = {}) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/maps/jobs/sold", params);
    console.log("sold jobs fetched ", data.data.length);
    return data;
  } catch (err: any) {
    console.log("error on fetchSoldJobs " + err);
    return { error: err.message };
  }
};

export const fetchJob = async (id: string) => {
  const apiService = new ApiService();
  const data = await apiService.get(apiUrl + "/jobs/" + id);
  return { data };
};

export const fetchFormFields = async () => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/jobform/fields");
    console.log("form-fields fetched ");
    return { data };
  } catch (err: any) {
    console.log("error on fetchFormFields " + err);
    throw err;
  }
};

export const updateJob = async (input: any) => {
  const { jobId, data } = input;
  if (!jobId) {
    throw new Error("updateJob: id is required");
  }
  const apiService = new ApiService();
  const resp = await apiService.put(apiUrl + "/jobs/" + jobId, data);
  console.log("job updated ");
  return { data: resp };
};

export const createJob = async (data: any = {}) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/jobs", data);
    console.log("job created ");
    return resp;
  } catch (err: any) {
    console.log("error on createJob " + err);
    return { error: err.message };
  }
};

export const duplicateJob = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/jobs/" + id + "/duplicate");
    console.log("job duplicated ");
    return resp;
  } catch (err: any) {
    console.log("error on duplicateJob " + err);
    throw err;
  }
};

export const deleteJob = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/jobs/" + id);
    console.log("job deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteJob " + err);
    return { error: err.message };
  }
};

export const saveJobFeatures = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + "/jobs/" + id + "/features",
      data
    );
    console.log("job features saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveJobFeatures " + err);
    return { error: err.message };
  }
};

export const saveJobLabels = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/jobs/" + id + "/labels", data);
    console.log("job labels saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveJobLabels " + err);
    return { error: err.message };
  }
};

export const saveJobChecklist = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + "/jobs/" + id + "/checklist",
      data
    );
    console.log("job checklist saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveJobChecklist " + err);
    return { error: err.message };
  }
};

export const fetchJobPdfString = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/jobs/" + id + "/pdf");
    console.log("job pdf fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchJobPdfString " + err);
    return { error: err.message };
  }
};

export const fetchInstallerPdfString = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/jobs/" + id + "/installer");
    console.log("installer pdf fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchInstallerPdfString " + err);
    return { error: err.message };
  }
};

export const fetchPdfString = async (id: string, model: string) => {
  try {
    const apiService = new ApiService();
    let url = apiUrl + "/jobs/" + id + "/" + model;

    switch (model) {
      case "pavers":
        url = apiUrl + "/pavers/" + id + "/pdf";
        break;
      case "paver-orders":
        url = apiUrl + "/paver-orders/" + id + "/pdf";
        break;
      case "repair":
        url = apiUrl + "/repairs/" + id + "/pdf";
        break;
    }

    const resp = await apiService.get(url);
    console.log("pdf fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchPdfString " + err);
    return { error: err.message };
  }
};

export const fetchProgressGroups = async () => {
  const apiService = new ApiService();
  const data = await apiService.get(apiUrl + "/jobs/progress-groups");
  console.log("progress groups fetched ");
  return data;
};

export const updateJobProgress = async (data: any) => {
  const apiService = new ApiService();
  const resp = await apiService.put(apiUrl + "/jobs/progress", data);
  return resp;
};
