import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../common/dateUtils";
import { TbEdit } from "react-icons/tb";
import { BiTrash } from "react-icons/bi";
import { deleteJob } from "../../../services/jobApiService";
import ConfirmDialog from "../../common/ConfirmDialog";
import { constants } from "../../../config/constants";
import { showToaster } from "../../../services/toastService";
import { IJob } from "../types";

interface IProps {
  jobs: any[];
  onDeleteJob: (id: string) => void;
}

const LeadJobsDisplay: React.FC<IProps> = (props) => {
  const { jobs, onDeleteJob } = props;
  const [state, setState] = React.useState({} as any);
  const navigate = useNavigate();

  function handleEditJob(id: string) {
    navigate(`/jobs/${id}/edit`);
  }

  async function handleDeleteJob() {
    handleCancelDelete();
    const resp = await deleteJob(state.id);
    if (resp.status == constants.SUCCESS_STATUS) {
      console.log("Deleted job " + state.id);
      onDeleteJob(state.id);
    } else {
      showToaster("Error deleting job " + JSON.stringify(resp.error), "error");
    }
  }

  function handleCancelDelete(): void {
    setState({ ...state, showDeleteModal: false });
  }

  function handleDeleteClick(id: string): void {
    setState({ ...state, showDeleteModal: true, id });
  }

  return (
    <>
      <ConfirmDialog
        show={state.showDeleteModal}
        message={`Are you sure you want to delete job: '${state.id}'?`}
        onHide={handleCancelDelete}
        handleDeleteClick={handleDeleteJob}
      />

      <div className="mt-1">
        <ul className="list-group">
          {jobs?.map((job: IJob) => (
            <li
              key={job.id}
              className={
                "list-group-item border mb-3 " +
                (job.date_sold ? "border-warning" : "")
              }
            >
              <div className="row">
                <Link
                  className="text-decoration-none col-9"
                  to={`/jobs/${job.id}`}
                >
                  <div className="col-10">
                    {job.code ?? job.id}
                    <span className="text-secondary ml-5">{job.name}</span>
                    <span className="text-secondary ml-5 fw-lighter fst-italic text-sm">
                      {formatDateTime(job.created_at)}
                    </span>
                    {job.date_sold && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                        Sold
                      </span>
                    )}
                  </div>
                </Link>
                <div
                  className="col d-flex justify-content-end"
                  style={{ maxHeight: "32px" }}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary mr-5"
                    title="Edit job"
                    onClick={() => handleEditJob(job.id)}
                  >
                    <TbEdit />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    title="Delete job"
                    onClick={() => handleDeleteClick(job.id)}
                  >
                    <BiTrash />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LeadJobsDisplay;
