import { Autocomplete, TextField } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { ToastContainer, toast } from "react-toastify";
import { formatErrorMessage } from "../../../common/utils";
import { fetchFormFields, importLeads } from "../../../services/leadApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import ProgressSpinner from "../../common/ProgressSpinner";
import { fieldsSettings } from "./fieldsSettings";
import { IDataExtract } from "./types";
import { invalidateQueries } from "../../../common/dataUtils";

const LeadsImport: React.FC = () => {
  const breadcrumbLinks = [
    { to: `/settings`, label: "Settings" },
    { to: `/settings/toolbox`, label: "Toolbox" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [dataImport, setDataImport] = useState<any[]>(null);
  const [sourceName, setSourceName] = useState<any>(null);
  const [newSource, setNewSource] = useState<string>("");

  const queryClient = useQueryClient();

  const { data, isError, error } = useQuery({
    queryKey: ["form-fields"],
    queryFn: fetchFormFields,
    staleTime: Infinity,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: importLeads,
  });

  function handleOnClose() {
    console.log("handleOnClose");
    setIsOpen(false);
  }

  function openImportDialog() {
    setIsOpen(true);
  }

  async function handleOnSubmit(data: IDataExtract, file: File): Promise<any> {
    console.log("handleOnSubmit", data);
    console.log("handleOnSubmit file", file);
    setDataImport(data.validData);
  }

  async function handleUploadData(): Promise<any> {
    const data = {
      data: dataImport,
      source_name: newSource || sourceName,
    };
    console.log("handleUploadData", data);
    try {
      const resp = await mutateAsync(data);
      console.log("handleUploadData", resp);
      const totalInserted = resp.data.length;
      toast.success(
        "Data uploaded successfully:" + totalInserted + " leads inserted",
        {
          autoClose: false,
        }
      );
      resetForm();
      invalidateQueries(queryClient, ["form-fields", "leads"]);
    } catch (err: any) {
      console.log("handleUploadData error", err);
      toast.error("Error uploading data" + formatErrorMessage(err.message));
    }
  }

  function cannotSubmit() {
    return !dataImport || (!sourceName && !newSource);
  }

  function handleSourceChanged(e: any, value: string, reason: string) {
    setSourceName(value);
  }

  function newSourceChanged(e: any) {
    setNewSource(e.target.value);
  }

  function resetForm() {
    setDataImport(null);
    setSourceName(null);
    setNewSource("");
  }

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={handleOnClose}
        onSubmit={handleOnSubmit}
        fields={fieldsSettings}
      />
      <ProgressSpinner show={isPending} />
      <ToastContainer />
      <div className="container">
        <BreadcrumbsComponent links={breadcrumbLinks} leaf="Toolbox" />
        <div className="py-1">
          <div className="settings-list-integrations">
            <div className="page-title">Import Leads from Excel</div>
            <div>
              {isError && (
                <div className="alert alert-danger" role="alert">
                  An error occurred. Please refresh the page.
                </div>
              )}
            </div>
            <div className="p-3 mt-3">
              <div className="form-label fw-semibold">
                Step 1: Locate your file and validate its data.
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={openImportDialog}
              >
                Get Data
              </button>
            </div>
            <div className="p-3 mt-3">
              <div className="form-label fw-semibold">
                Step 2: Choose a Source for the new leads.
              </div>
              <div className="row"></div>
              <div className="row">
                <div className="col-4">
                  <Autocomplete
                    value={sourceName}
                    options={data?.data?.sources?.map(
                      (option: any) => option.name
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Search" />
                    )}
                    onChange={handleSourceChanged}
                  />
                </div>
              </div>
              <div className="row">
                <div className="row mt-3">
                  <div className="form-label">Or create a new source</div>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    className="form-control"
                    value={newSource}
                    onChange={newSourceChanged}
                  />
                </div>
              </div>
            </div>
            <div className="p-3 mt-3">
              <div className="form-label fw-semibold">
                Step 3: Upload the data.
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={handleUploadData}
                disabled={cannotSubmit()}
              >
                Upload Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsImport;
