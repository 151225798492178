import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchTemplates = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/templates");
    console.log("templates fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchTemplates " + err);
    throw err;
  }
};

export const createTemplate = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/templates", data);
    console.log("template created ");
    return resp;
  } catch (err: any) {
    console.log("error on createTemplate " + err);
    throw err;
  }
};

export const updateTemplate = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/templates/" + data.id, data);
    console.log("template updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updateTemplate " + err);
    throw err;
  }
};

export const updateTemplatesOrder = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/templates/all", data);
    console.log("templates order updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updateTemplatesOrder " + err);
    throw err;
  }
};

export const deleteTemplate = async (id: number) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/templates/" + id);
    console.log("template deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteTemplate " + err);
    throw err;
  }
};
