import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export interface ILocation {
  location?: any; // location=40.2346673,-111.7149162&mode=reset
  mode?: string;
  radius?: number;
}

export const fetchNearByServices = async (params: ILocation = {}) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/maps/nearby-services", params);
    console.log("services fetched ", resp);
    return resp;
  } catch (err) {
    console.log("error on fetchNote " + err);
    return { error: err };
  }
};
