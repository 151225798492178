import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React from "react";
import { Modal } from "react-bootstrap";

interface IProps {
  base64String: string;
  show: boolean;
  onClose: () => void;
}

const PDFViewer: React.FC<IProps> = (props) => {
  const { base64String } = props;

  return (
    <Modal
      keyboard={true}
      show={props.show}
      className="modal-lg"
      onHide={props.onClose}
      fullscreen={"false"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>PDF Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            {base64String && (
              <div className="pdf-viewer-container">
                <Viewer
                  fileUrl={base64String}
                  defaultScale={SpecialZoomLevel.PageFit}
                />
              </div>
            )}
          </Worker>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PDFViewer;
