import { useMutation, useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { fileToBase64, formatErrorMessage } from "../../../common/utils";
import {
  fetchAccountInfo,
  updateAccountInfo,
  updateAccountLogo,
} from "../../../services/accountApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import LogoUpdateDialog from "./LogoUpdateDialog";
import { ICompany } from "./types";

const CompanyDetails = () => {
  const [company, setCompany] = useState<ICompany>({} as any);
  const [showDialog, setShowDialog] = useState(false);
  const [uniqueId, setUniqueId] = useState(Date.now());

  const { data, isError, error } = useQuery({
    queryKey: ["company"],
    queryFn: fetchAccountInfo,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateAccountInfo,
  });

  const { mutateAsync: updateLogoAsync } = useMutation({
    mutationFn: updateAccountLogo,
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (data?.data) {
      const processed = processData(data.data);
      setCompany(processed);
      reset(processed);
    }
    if (isError) {
      toast.error(
        "Failed to fetch company " + formatErrorMessage(error.message)
      );
    }
  }, [data]);

  function processData(data: any) {
    return {
      ...data,
      file: undefined,
    };
  }

  async function handleUpdateCompany(data: any) {
    console.log("handleUpdateCompany");
    console.log(errors, isDirty);
    console.log("data", data);
    if (data.file) {
      data.file = await fileToBase64(data.file);
    }
    if (!isEmpty(errors) || !isDirty) return;

    try {
      const resp = await mutateAsync(data);
      const processed = processData(resp.data);
      setCompany(processed);
      reset(processed);
      toast.success("Company updated successfully");
    } catch (err: any) {
      toast.error(
        "Failed to update company " + formatErrorMessage(err.message)
      );
    }
  }

  function resetForm() {
    reset();
  }

  function handleShowDialog() {
    setShowDialog(true);
  }

  function handleHideDialog() {
    setShowDialog(false);
  }

  async function handleOnSaveLogo(data: any) {
    console.log("handleOnSaveLogo", data);
    setShowDialog(false);

    try {
      await updateLogoAsync({ file: await fileToBase64(data) });
      setUniqueId(Date.now());
      toast.success("Logo updated successfully");
    } catch (err: any) {
      console.log("addJobLineItemsFromNotes error:", err);
      toast.error("Error: " + formatErrorMessage(err.message));
    }
  }

  const breadcrumbLinks = [{ to: "/settings", label: "Settings" }];

  return (
    <>
      <ToastContainer />
      <LogoUpdateDialog
        show={showDialog}
        onHide={handleHideDialog}
        onSave={handleOnSaveLogo}
      />
      <div className="container">
        <BreadcrumbsComponent links={breadcrumbLinks} leaf="Company" />
        <div className="d-flex justify-content-between">
          <div className="page-title">Company Details</div>
        </div>
      </div>
      <div className="container">
        <form
          style={{ width: 600 }}
          onSubmit={handleSubmit(handleUpdateCompany)}
        >
          <div className="form-group mb-3">
            <label htmlFor="company_name">Logo</label>
            <div className="row mt-1">
              <div className="preview bg-light col-auto">
                <img
                  src={`${company.logo_url}?${uniqueId}`}
                  alt="Image preview..."
                  style={{ width: 200 }}
                />
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-secondary ml-2"
                  onClick={handleShowDialog}
                >
                  Update Image
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="company_name">Company Name</label>
            <input
              type="text"
              className="form-control"
              {...register("company_name", { required: "field is required" })}
            />
            <div className="form-text text-danger mb-2" style={{ height: 10 }}>
              {errors.company_name
                ? errors.company_name.message?.toString()
                : ""}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              className="form-control"
              placeholder="801-123-4567"
              {...register("phone", {
                required: "invalid phone number",
                pattern: {
                  value: /^\d{3}-\d{3}-\d{4}$/,
                  message: "invalid phone number",
                },
              })}
            />
            <div className="form-text text-danger mb-2" style={{ height: 10 }}>
              {errors.phone ? errors.phone.message?.toString() : ""}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="pavers_phone">Phone 2 (for orders)</label>
            <input
              type="text"
              className="form-control"
              placeholder="801-123-4567"
              {...register("pavers_phone", {
                required: "invalid phone number",
                pattern: {
                  value: /^\d{3}-\d{3}-\d{4}$/,
                  message: "invalid phone number",
                },
              })}
            />
            <div className="form-text text-danger mb-2" style={{ height: 10 }}>
              {errors.phone ? errors.phone.message?.toString() : ""}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              {...register("email", {
                required: "field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "invalid email",
                },
              })}
            />
            <div className="form-text text-danger mb-2" style={{ height: 10 }}>
              {errors.email ? errors.email.message?.toString() : ""}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              className="form-control"
              {...register("address", { required: "field is required" })}
            />
            <div className="form-text text-danger mb-2" style={{ height: 10 }}>
              {errors.address ? errors.address.message?.toString() : ""}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-8">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                placeholder="Provo"
                {...register("city", { required: "field is required" })}
              />
              <div
                className="form-text text-danger mb-2"
                style={{ height: 10 }}
              >
                {errors.city ? errors.city.message?.toString() : ""}
              </div>
            </div>
            <div className="form-group col-2">
              <label htmlFor="state">State</label>
              <input
                type="text"
                className="form-control"
                placeholder="UT"
                {...register("state", {
                  required: "invalid state",
                  pattern: { value: /^[A-Z]{2}$/, message: "invalid state" },
                })}
              />
              <div
                className="form-text text-danger mb-2"
                style={{ height: 10 }}
              >
                {errors.state ? errors.state.message?.toString() : ""}
              </div>
            </div>
            <div className="form-group col-2">
              <label htmlFor="zip">Zip</label>
              <input
                type="text"
                className="form-control"
                placeholder="84606"
                {...register("zip", {
                  required: "invalid zip code",
                  pattern: {
                    value: /^\d{5}(?:-\d{4})?$/,
                    message: "invalid zip code",
                  },
                })}
              />
              <div
                className="form-text text-danger mb-2"
                style={{ height: 10 }}
              >
                {errors.zip ? errors.zip.message?.toString() : ""}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address_2">Address 2</label>
            <input
              type="text"
              className="form-control"
              {...register("address_2")}
            />
            <div
              className="form-text text-danger mb-2"
              style={{ height: 10 }}
            ></div>
          </div>

          {isDirty && (
            <div className="row ml-1 mt-3 mb-5">
              <button type="submit" className="btn btn-primary col-auto">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary col-auto ml-2"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default CompanyDetails;
