import { colors } from "../../../config/constants";
import { IJobProgress } from "./types";

export function convertHashToArray(resp: any) {
  const progressStatus = [
    " Just Sold",
    "On Hold",
    "To Start",
    "In Progress",
    "Clean Up",
    "Waiting for Final Payment",
  ];
  console.log("respData", resp?.data);

  if (!resp?.data) return [];

  const data = resp.data;
  const flattedData = Object.keys(data).flatMap((key) => {
    if (!progressStatus.includes(key)) return [];
    return data[key].map((item: IJobProgress) => item);
  });
  return flattedData;
}

export async function findLeadsGeocode(data: IJobProgress[], apiKey: string) {
  console.log("findLeadsGeocode", data);
  const geocodeBaseUrl = "https://maps.googleapis.com/maps/api/geocode/json";

  const updatedData = await Promise.all(
    data.map(async (item) => {
      // Check if geocode is missing or incomplete
      console.log("item.lead.geocode before", item.lead.geocode);
      item.lead.geocode = tryParseJSON(item.lead.geocode);
      console.log(
        "item.lead.geocode after",
        item.lead.geocode,
        item.lead.contact_name
      );
      if (
        !item.lead.geocode ||
        !item.lead.geocode.lat ||
        !item.lead.geocode.lng
      ) {
        console.log("item.lead.geocode inside", item.lead.geocode);
        console.log("Fetching geocode for address:", item.lead.customer_name);
        const addr = `${item.lead.street}, ${item.lead.city}, ${item.lead.state}`;
        const address = encodeURIComponent(addr);
        const geocodeUrl = `${geocodeBaseUrl}?address=${address}&key=${apiKey}`;

        try {
          const response = await fetch(geocodeUrl);
          const json = await response.json();
          if (json.status === "OK" && json.results.length > 0) {
            const { lat, lng } = json.results[0].geometry.location;
            // Update item with new geocode
            item.lead.geocode = { lat, lng };
          }
        } catch (error) {
          console.error(
            "Failed to fetch geocode for address:",
            item.lead.street,
            error
          );
          // Handle error appropriately
        }
      }
      return item;
    })
  );
  console.log("findLeadsGeocode updated data", data);
  return updatedData;
}

export function tryParseJSON(jsonString: any) {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    console.error("Error parsing JSON", e);
    return {};
  }
  return {};
}

export const JobStatus = {
  JustSold: "Just Sold",
  OnHold: "On Hold",
  ToStart: "To Start",
  InProgress: "In Progress",
  CleanUp: "Clean Up",
  WaitingPayment: "Waiting for Final Payment",
};

export function getMarkerBgColor(status: string) {
  switch (status) {
    case JobStatus.JustSold:
      return colors.magenta;
    case JobStatus.OnHold:
      return colors.yellow;
    case JobStatus.ToStart:
      return colors.green;
    case JobStatus.InProgress:
      return colors.pink;
    case JobStatus.CleanUp:
      return colors.blue;
    case JobStatus.WaitingPayment:
      return colors.purple;
    default:
      return colors.orange;
  }
}
