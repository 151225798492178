import React from "react";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { RiDraggable } from "react-icons/ri";
import { ILineItem } from "../types";
import { SortableKnob } from "react-easy-sort";

interface ILineItemProps {
  lineItem: ILineItem;
  register: any;
  index: number;
  handleQuantityRateBlur: (e: any, id: number) => void;
  deleteLineItem: (e: any, id: number) => void;
  handleTotalBlur: (e: any, id: number) => void;
  editLineItem: (id: any) => void;
  onCheckboxChanged: (index: any, checked: boolean) => void;
}

const JobLineItem: React.FC<ILineItemProps> = (props: ILineItemProps) => {
  const { lineItem, index, register, deleteLineItem } = props;

  function handleQuantityRateBlur(event: any): void {
    if (props.handleQuantityRateBlur) {
      props.handleQuantityRateBlur(event, index);
    }
  }

  function handleTotalBlur(event: any): void {
    if (props.handleTotalBlur) {
      props.handleTotalBlur(event, index);
    }
  }

  function editLineItem() {
    console.log("Editing line item: ", lineItem);
    props.editLineItem(index);
  }

  function checkboxChange(e: any): void {
    const isChecked = e.target.checked;
    props.onCheckboxChanged(index, isChecked);
  }

  return (
    <div className="d-flex" style={{ width: "100%" }}>
      <div className="flex-fill">
        <SortableKnob>
          <button
            type="button"
            title="drag to reorder"
            className="btn btn-light btn-sm"
          >
            <RiDraggable />
          </button>
        </SortableKnob>
      </div>
      <div className="flex-fill">
        {/* description */}
        <textarea
          className="form-control form-control-sm line-item-description"
          {...register(`lineItems.${index}.description`)}
        ></textarea>
      </div>
      <div className="flex-fill">
        {/* //quantity */}
        <input
          type="text"
          style={{ minWidth: "100px" }}
          className="form-control form-control-sm line-item"
          {...register(`lineItems.${index}.quantity`, {
            onBlur: handleQuantityRateBlur,
          })}
        />
      </div>
      <div className="flex-fill">
        {/* unit */}
        <input
          type="text"
          style={{ minWidth: "100px" }}
          className="form-control form-control-sm line-item"
          {...register(`lineItems.${index}.unit`)}
        />
      </div>
      <div className="flex-fill">
        <input
          type="text"
          style={{ minWidth: "100px" }}
          className="form-control form-control-sm line-item"
          {...register(`lineItems.${index}.rate`, {
            onBlur: handleQuantityRateBlur,
          })}
        />
      </div>
      <div className="flex-fill">
        <input
          type="text"
          style={{ minWidth: "100px" }}
          className="form-control form-control-sm line-item"
          {...register(`lineItems.${index}.total`, {
            onBlur: handleTotalBlur,
          })}
        />
      </div>
      <div className="flex-fill">
        <div className="btn-group">
          <div className="dropdown">
            <button
              className="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#" onClick={editLineItem}>
                  <BsPencilSquare className="mr-2 mb-1" />
                  Edit
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => deleteLineItem(e, lineItem.id)}
                >
                  <BsTrash className="mr-2 mb-1" />
                  Remove
                </a>
              </li>
            </ul>
          </div>
          <div>
            <input
              className="ml-1 mt-2"
              type="checkbox"
              name="isSelected"
              {...register(`lineItems.${index}.selected`)}
              onChange={checkboxChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobLineItem;
