import { convert } from "html-to-text";
import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { usePanelsContext } from "../../../context/PanelsContext";
import { fetchTemplates } from "../../../services/proposalApiService";
import { IProposalTemplate } from "../types";
import { getOptionList } from "../../../common/dataUtils";

interface IProps {
  jobId: any;
  addJobLineItems: (lines: IProposalTemplate[]) => void;
}

const ProposalTemplatePane: React.FC<IProps> = (props) => {
  const [templates, setTemplates] = useState<IProposalTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<
    IProposalTemplate[]
  >([]);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const { panels, setPanelDisplay } = usePanelsContext();
  const [options, setOptions] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const isPaneOpen = !!panels.templates;
  const showNotes = panels.showNotes;

  useEffect(() => {
    if (isPaneOpen) {
      filterTemplates();
      createOptionList();
    }
  }, [isPaneOpen, showNotes]);

  useEffect(() => {
    handleFetchTemplates();
  }, []);

  useEffect(() => {
    selectAllItems();
  }, [allSelected]);

  function selectAllItems(): any {
    const checkedItems = filteredTemplates.reduce(
      (acc: Record<string, boolean>, temp) => {
        acc[temp.id] = allSelected;
        return acc;
      },
      {}
    );

    setCheckedItems(checkedItems);
  }

  function handleFetchTemplates() {
    fetchTemplates()
      .then((resp: any) => {
        if (resp.data) {
          setTemplates(resp.data);
        } else {
          console.log("Error fetching templates", resp);
        }
      })
      .catch((err: any) => console.log("Error fetching templates", err));
  }

  function handleChecklistChange(event: any) {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  }

  function handleAddLineClick() {
    const type = showNotes ? "note" : "line-item";
    const lines = templates.filter(
      (temp: IProposalTemplate) => checkedItems[temp.id] && temp.type == type
    );
    props.addJobLineItems(lines);
    handleClosePane();
  }

  function cleanText(text: string): string {
    const newText = convert(text);
    return newText.replace(/^-/, "");
  }

  function filterTemplates() {
    const filtered = getFilteredTemplates();
    setFilteredTemplates(filtered);
  }

  function getFilteredTemplates() {
    const selectedType = showNotes ? "note" : "line-item";
    const filtered = templates.filter(
      (temp: IProposalTemplate) => temp.type == selectedType
    );
    return filtered;
  }

  function createOptionList() {
    const options = getOptionList(templates);
    setOptions(options);
  }

  function handleClosePane(): void {
    setPanelDisplay("templates", false);
  }

  function handleGroupSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value: selectedGroup } = event.target;
    if (!selectedGroup || selectedGroup === "All") {
      filterTemplates();
      return;
    }

    const filtered = getFilteredTemplates().filter(
      (temp: IProposalTemplate) => {
        const groups = temp.group?.split(",") || [];
        return groups.some(
          (g) => g.trim().toLowerCase() === selectedGroup.trim().toLowerCase()
        );
      }
    );

    setCheckedItems(checkedItems);
    setFilteredTemplates(filtered);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={isPaneOpen}
        title={showNotes ? "Notes Templates" : "Line Templates"}
        width="40%"
        onRequestClose={handleClosePane}
      >
        <div className="container-fluid">
          <div>
            <select className="form-control" onChange={handleGroupSelect}>
              <option key="all">All</option>
              {options.map((option: string) => (
                <option key={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="list-group">
            {filteredTemplates.map((template: IProposalTemplate) => (
              <div key={template.id} className="card my-2">
                <div className="card-body">
                  <input
                    type="checkbox"
                    className="mr-3"
                    name={template.id + ""}
                    checked={checkedItems[template.id]}
                    onChange={handleChecklistChange}
                  />
                  <span className="card-text ">{cleanText(template.text)}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div className="form-check mr-3 mt-2">
              <input
                type="checkbox"
                className="mr-3"
                checked={allSelected}
                onChange={() => setAllSelected(!allSelected)}
              />
              <span className="card-text">Select All</span>
            </div>
            <button className="btn btn-primary" onClick={handleAddLineClick}>
              Add Lines
            </button>
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

export default ProposalTemplatePane;
