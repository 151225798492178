import { toast } from "react-toastify";

export const showToaster = (message: string, type = "success") => {
  // type: 'info', 'success', 'warning', 'error', 'default'`
  const options: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    type: type,
  };

  toast(message, options);
};
