import { Paper } from "@mui/material";
import arrayMove from "array-move";
import React, { useEffect, useState, useMemo } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { RiDraggable } from "react-icons/ri";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import {
  deleteTemplate,
  updateTemplatesOrder,
} from "../../../services/proposalApiService";
import { showToaster } from "../../../services/toastService";
import CreateTemplateDialog from "./CreateTemplateDialog";
import { getOptionList } from "../../../common/dataUtils";
import { IProposalTemplate } from "../../job/types";

interface IProps {
  templates: IProposalTemplate[];
  onListUpdated: (templates: IProposalTemplate[]) => void;
}

let timeoutId: any = null;

const ProposalTemplatesList: React.FC<IProps> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [template, setTemplate] = useState<any>({});
  const [templates, setTemplates] = useState<IProposalTemplate[]>([]);
  const [search, setSearch] = useState("");
  const [sorted, setSorted] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("All");

  const { mutateAsync: mutateUpdateOrder } = useMutation({
    mutationFn: updateTemplatesOrder,
  });
  const { mutateAsync: mutateDeleteTemplate } = useMutation({
    mutationFn: deleteTemplate,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    filterTemplateList();
    createOptionList();
  }, [props.templates]);

  useEffect(() => {
    filterTemplateList();
  }, [search, selectedGroup]);

  useEffect(() => {
    if (sorted) {
      debounceSaveTemplates();
      setSorted(false);
    }
  }, [templates]);

  function debounceSaveTemplates() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      saveTemplates();
    }, 3000);
  }

  async function saveTemplates() {
    const temps = getUpdatedRank(templates);

    try {
      const resp = await mutateUpdateOrder(temps);
      queryClient.invalidateQueries({ queryKey: ["proposal-templates"] });
      showToaster("Templates order updated successfully.");
    } catch (err: any) {
      showToaster("Failed to update templates order: " + err.message, "error");
      setTemplates(props.templates);
    }
  }

  function getUpdatedRank(templates: IProposalTemplate[]): IProposalTemplate[] {
    return templates.map((temp: IProposalTemplate, index: number) => {
      return { ...temp, rank: index };
    });
  }

  function showEditDialog(id: number): any {
    const temp = templates.find((temp: IProposalTemplate) => temp.id === id);
    setTemplate(temp);
    setDialogIsOpen(true);
  }

  async function showDeleteDialog(id: number): Promise<void> {
    if (id) {
      const result = await Swal.fire({
        text: "Are you sure you want to delete this template?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (result.isConfirmed) {
        try {
          await mutateDeleteTemplate(id);
          queryClient.invalidateQueries({ queryKey: ["proposal-templates"] });
          showToaster("Template deleted successfully.");
        } catch (err: any) {
          showToaster("Failed to delete template: " + err.message, "error");
        }
      }
    }
  }

  function handleOnDialogClosed(): void {
    setDialogIsOpen(false);
  }

  function updateTemplatesList(template: any): void {
    const temps = templates.map((temp) => {
      if (temp.id === template.id) {
        return template;
      }
      return temp;
    });
    props.onListUpdated(temps);
  }

  function handleSearchChanged(event: any): void {
    setSearch(event.target.value);
  }

  function filterBySearchText(
    templates: IProposalTemplate[],
    search: string
  ): IProposalTemplate[] {
    if (!search) {
      return templates;
    }
    const lowerCaseSearch = search.toLowerCase();
    return templates.filter((temp: IProposalTemplate) =>
      temp.text?.toLowerCase().includes(lowerCaseSearch)
    );
  }

  function filterByGroup(
    templates: IProposalTemplate[],
    selectedGroup: string
  ): IProposalTemplate[] {
    if (selectedGroup === "All") {
      return templates;
    }
    const lowerCaseSelectedGroup = selectedGroup.trim().toLowerCase();
    return templates.filter((temp: IProposalTemplate) => {
      const groups = temp.group?.split(",") || [];
      return groups.some(
        (g) => g.trim().toLowerCase() === lowerCaseSelectedGroup
      );
    });
  }

  function filterTemplateList() {
    // todo - refactor this function using useMemo
    const { templates } = props;
    const searchResults = filterBySearchText(templates, search);
    const filteredByGroup = filterByGroup(templates, selectedGroup);
    const filteredTemplates = searchResults.filter((temp: IProposalTemplate) =>
      filteredByGroup.some((f) => f.id === temp.id)
    );

    setTemplates(filteredTemplates);
  }

  function handleClearClick() {
    setSearch("");
  }

  function deleteListItem(id: number) {
    const temps = templates.filter((temp: IProposalTemplate) => temp.id !== id);
    props.onListUpdated(temps);
  }

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    setTemplates((array) => arrayMove(array, oldIndex, newIndex));
    setSorted(true);
  };

  function createOptionList() {
    const options = getOptionList(props.templates);
    setOptions(options);
  }

  function handleGroupSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedGroup(event.target.value);
  }

  return (
    <>
      <ToastContainer />
      <CreateTemplateDialog
        isOpen={dialogIsOpen}
        onDialogClosed={handleOnDialogClosed}
        onTemplateUpdated={updateTemplatesList}
        template={template}
      />

      <div className="row mb-3 ml-3">
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={handleSearchChanged}
            value={search}
          />
        </div>
        <div className="col">
          <button className="btn btn-default" onClick={handleClearClick}>
            Clear
          </button>
        </div>
        <div className="col-3">
          <select className="form-control" onChange={handleGroupSelect}>
            <option key="all">All</option>
            {options.map((option: string) => (
              <option key={option}>{option}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-4">
        <SortableList
          onSortEnd={handleSortEnd}
          className="sort-list"
          draggedItemClassName="sort-item-dragged"
        >
          {templates.map((temp: IProposalTemplate, index: number) => (
            <SortableItem key={temp.id}>
              <Paper elevation={3} key={temp.id} className="p-2 m-3 sort-item">
                <div className="">
                  <div className="d-flex">
                    <div>
                      <a
                        type="button"
                        className="btn btn-small "
                        title="drag to reorder"
                      >
                        <RiDraggable />
                      </a>
                    </div>
                    <div className="flex-grow-1">
                      <div
                        className="my-2"
                        dangerouslySetInnerHTML={{
                          __html: temp.text?.replace(/\n/g, "<br>"),
                        }}
                      />
                    </div>
                    <div className="sort-item-actions">
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm mr-2"
                          title="edit template"
                          onClick={() => showEditDialog(temp.id)}
                        >
                          <BsPencilFill />
                        </button>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => showDeleteDialog(temp.id)}
                        >
                          <BsTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="ms-5">
                    <span className="fw-semibold">Group:</span>
                    <span className="ps-3">{temp.group || "none"}</span>
                  </div>
                </div>
              </Paper>
            </SortableItem>
          ))}
        </SortableList>
      </div>
    </>
  );
};

export default ProposalTemplatesList;
