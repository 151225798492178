import {
  ColumnDirective,
  ColumnsDirective,
  KanbanComponent,
  DialogFieldsModel,
} from "@syncfusion/ej2-react-kanban";
import React, { useEffect, useState } from "react";
import { formatErrorMessage } from "../../../common/utils";
import {
  fetchProgressGroups,
  updateJobProgress,
} from "../../../services/jobApiService";
import "./kanban.css";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IJobProgress } from "./types";

const JobProgress: React.FC = () => {
  const [progressGroups, setProgressGroups] = useState([]);
  const navigate = useNavigate();
  let initialStatus = "";

  const { data, isError, error } = useQuery({
    queryKey: ["progressGroups"],
    queryFn: fetchProgressGroups,
    staleTime: 1000 * 60 * 5,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateJobProgress,
  });

  useEffect(() => {
    processData(data);
    if (isError) {
      console.error("Error occurred:", error.message);
    }
  }, [data, error]);

  function processData(resp: any) {
    const { data: respData, error } = resp || {};
    if (respData) {
      const kData = Object.keys(respData).flatMap((key) => {
        return respData[key].map((item: IJobProgress) => ({
          Status: key,
          Id: item.id + "",
          Number: item.code || "ID-" + item.id,
          Summary: item.lead.customer_name + ": " + item.name,
          Type: "Story",
          RankId: item.id,
        }));
      });

      setProgressGroups(kData);
    } else if (error) {
      console.error("fetchData error: ", formatErrorMessage(error));
    }
  }

  const fields: DialogFieldsModel[] = [
    { key: "Number", type: "TextBox" },
    { key: "Status", type: "DropDown" },
    { key: "Summary", type: "TextArea", text: "Job" },
  ];

  function onDialogOpen(args: any) {
    initialStatus = args?.data?.Status;
    console.log("onDialogOpen", initialStatus);
  }

  async function onActionComplete(event: any) {
    const record = event.changedRecords?.[0] || {};
    const data = {
      job_id: record.Id,
      status: record.Status,
      old_status: initialStatus,
    };
    console.log("onActionComplete", record);
    const hasChanged = record.Status && record.Status != initialStatus;
    if (hasChanged) {
      await updateJobStatus(data);
    }
  }

  async function updateJobStatus(data: any) {
    try {
      await mutateAsync(data);
      return true;
    } catch (error: any) {
      console.log("updateJobStatus error: ", formatErrorMessage(error.message));
      return false;
    }
  }

  function onDragStart(args: any) {
    initialStatus = args.data[0].Status;
  }

  function cardTemplate(props: any) {
    return (
      <div className="card-template">
        <div className="e-card-content">
          <div className="card-template-wrap px-2">
            <div>
              <b>{props.Number}</b>
            </div>
            <div>{props.Summary}</div>
            <Link
              className="text-decoration-none"
              to={`/jobs/${props.Id}/edit`}
            >
              Edit Job
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3" style={{}}>
      <div className="d-flex justify-content-between">
        <div className="page-title">Jobs Progress</div>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => navigate("/jobs/progress/map")}
          >
            Map View
          </button>
        </div>
      </div>
      <KanbanComponent
        id="kanban"
        keyField="Status"
        dataSource={progressGroups}
        cardSettings={{
          contentField: "Summary",
          headerField: "Number",
          template: cardTemplate.bind(this),
        }}
        dialogOpen={onDialogOpen}
        actionComplete={onActionComplete}
        dragStart={onDragStart}
        enablePersistence={true}
        dialogSettings={{ fields }}
        width="auto"
      >
        <ColumnsDirective>
          <ColumnDirective headerText="Just Sold" keyField="Just Sold" />
          <ColumnDirective headerText="On Hold" keyField="On Hold" />
          <ColumnDirective headerText="To Start" keyField="To Start" />
          <ColumnDirective headerText="In Progress" keyField="In Progress" />
          <ColumnDirective headerText="Clean Up" keyField="Clean Up" />
          <ColumnDirective
            headerText="Waiting Payment"
            keyField="Waiting for Final Payment"
          />
          <ColumnDirective
            headerText="Completed"
            keyField="Paid+Completed"
            allowToggle={true}
            isExpanded={false}
          />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
};

export default JobProgress;
