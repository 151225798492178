import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import LeadLabels from "./LeadLabels";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  register?: any;
  labels: any[];
  leadLabels: any[];
  leadId: string;
  updateLeadLabels: (labels: any[]) => void;
  setLoading: (loading: boolean) => void;
}

const LeadLabelsPane: React.FC<IProps> = (props) => {
  const { leadLabels, labels, leadId } = props;
  const { panels, setPanelDisplay } = usePanelsContext();
  const isOpen = !!panels.labels;

  function handleClose() {
    setPanelDisplay("labels", false);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={isOpen}
        title="Labels"
        width="40%"
        onRequestClose={handleClose}
      >
        <LeadLabels
          labels={labels}
          leadId={leadId}
          leadLabels={leadLabels}
          updateLeadLabels={(labels) => props.updateLeadLabels(labels)}
          setLoading={(loading: boolean) => props.setLoading(loading)}
        />
      </SlidingPane>
    </div>
  );
};

export default LeadLabelsPane;
