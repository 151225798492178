import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IRole, IUser } from "./types";
import { fetchRoles, updateUser } from "../../../services/userApiService";
import { useForm } from "react-hook-form";
import { generatePassword } from "../../../common/utils";
import { showToaster } from "../../../services/toastService";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery } from "@tanstack/react-query";

interface IProps {
  show: boolean;
  user: any;
  onHide: (e?: any) => void;
  updateUsersList: (user: IUser) => void;
}

const UserEditDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);

  const { data: rolesData } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRoles,
    staleTime: Infinity,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateUser,
  });

  useEffect(() => {
    setRoles(rolesData?.data || []);
  }, [rolesData]);

  useEffect(() => {
    setShowModal(props.show);
    reset(props.user);
  }, [props.show]);

  const {
    register,
    reset,
    formState: { isDirty, dirtyFields, errors },
    getValues,
    setValue,
  } = useForm();

  const handleCloseDialog = () => {
    setShowModal(false);
    props.onHide();
  };

  const handleSaveUser = async () => {
    const data: any = getValues();

    const resp: any = await mutateAsync(data);
    handleCloseDialog();

    if (resp.data) {
      console.log("Saved user ", resp.data);
      props.updateUsersList(resp.data);
      if (data.password) {
        navigator.clipboard.writeText(data.password);
        showToaster("Password copied to clipboard");
      } else {
        showToaster("User updated: " + resp.data.name);
      }
    } else {
      showToaster("Error saving user: " + JSON.stringify(resp.error), "error");
    }
  };

  function setPassword(): void {
    const password = generatePassword();
    setValue("password", password);
    setValue("password_confirmation", password);
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
      dialogClassName="modal-wide"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Joe Doe"
                {...register("name")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="display_name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Display Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("display_name")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="email"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Email
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("email")}
                disabled={true}
                placeholder="name@email.com"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="phone"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Phone
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("phone")}
                placeholder="000-000-0000"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="password"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Password
            </label>
            <div className="col-sm-8">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={null}
                  {...register("password")}
                  autoComplete="new-password"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={setPassword}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="password_confirmation"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Confirm Password
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                defaultValue={null}
                autoComplete="off"
                {...register("password_confirmation")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="role"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Permission Level
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("role_id")}>
                {roles &&
                  roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col">
              <Tooltip title="user:sales reps, super user:supervisors">
                <a href="#" className="btn btn-sm btn-outline-secondary">
                  ?
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="position"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Role
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("position")}>
                <option value="none">None</option>
                <option value="office">Office</option>
                <option value="sales-rep">Sales Rep</option>
                <option value="installer">Installer</option>
              </select>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="active"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Status
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("active")}>
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </select>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="active"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Notes
            </label>
            <div className="col-sm-8">
              <textarea
                className="form-control"
                {...register("notes")}
              ></textarea>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSaveUser} disabled={!isDirty}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserEditDialog;
