import React, { useState } from "react";
import ConfirmDialog from "../../common/ConfirmDialog";
import { deletePhone } from "../../../services/phoneApiService";
import { constants } from "../../../config/constants";
import { showToaster } from "../../../services/toastService";

interface Props {
  phone: any;
  onDeletePhone?: (id: any) => void;
}

const PhoneDisplay: React.FC<Props> = (props) => {
  const { phone, onDeletePhone } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const callPhone = () => {
    window.location.href = `tel:${phone.number}`;
  };

  const handleDeletePhoneClick = (data: any = {}) => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  async function handleDeletePhone() {
    handleCancelDelete();
    const resp = await deletePhone(phone.id);
    if (resp.status == constants.SUCCESS_STATUS) {
      onDeletePhone(phone.id);
    } else {
      showToaster(
        "Error deleting phone " + JSON.stringify(resp.error),
        "error"
      );
    }
  }

  return (
    <>
      <ConfirmDialog
        show={showDeleteModal}
        message={`Are you sure you want to delete phone: '${phone.label}'?`}
        onHide={handleCancelDelete}
        handleDeleteClick={handleDeletePhone}
      />
      <div className="input-group mt-2">
        <input
          type="tel"
          className="form-control"
          aria-label="phone-number"
          placeholder="Phone #"
          readOnly
          value={phone.number}
        />

        <div className="btn-group">
          <span className="input-group-text" id="basic-addon2">
            {phone.label ?? "other"}
          </span>
          <button className="btn btn-default" type="button" onClick={callPhone}>
            <i className="bi bi-telephone-outbound-fill"></i>
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={handleDeletePhoneClick}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default PhoneDisplay;
