import React from "react";

const TermsOfService: React.FC = () => {
  return (
    <div className="container py-5">
      <h1>Terms of Service</h1>
      <p>
        These Terms of Service ("Terms", "ToS") govern your use of our internal
        application ("Application", "Service") operated by <u>Team Pavers</u>{" "}
        ("us", "we", or "our"). Your access to and use of the Service is
        conditioned upon your acceptance of and compliance with these Terms.
        These Terms apply to all employees, contractors, and affiliates who
        access or use the Service.
      </p>
      <h2>Access and Use</h2>
      <p>
        The Service is intended solely for the use of <u>Team Pavers</u>{" "}
        employees and authorized individuals to facilitate the workflow of
        selling, installing, and delivering pavers jobs. By accessing or using
        the Service, you agree to use it in compliance with these Terms and all
        applicable laws, regulations, and company policies.
      </p>
      <h2>Prohibited Conduct</h2>
      <p>
        You agree not to use the Service for any purpose that is illegal or
        unauthorized by these Terms, including but not limited to:
        <ul>
          <li>
            Any form of discrimination, harassment, or harmful behavior towards
            others.
          </li>
          <li>
            Sharing confidential information without proper authorization.
          </li>
          <li>
            Introducing malicious software or attempting to compromise the
            security of the Service.
          </li>
          <li>
            Using customer or job information for personal gain or any
            unauthorized purpose, which is considered illegal and a violation of
            these Terms.
          </li>
        </ul>
      </p>
      <h2>Intellectual Property</h2>
      <p>
        All intellectual property rights in the Service and its content,
        including but not limited to software, text, graphics, logos, and
        trademarks, are owned by <u>Team Pavers</u> and are protected by
        intellectual property laws. You agree not to reproduce, distribute,
        modify, or create derivative works of any part of the Service without
        prior written permission from us.
      </p>
      <h2>Termination</h2>
      <p>
        We may terminate or suspend your access to the Service immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach these Terms. Upon termination, your
        right to use the Service will cease immediately.
      </p>
      <h2>Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. We will provide reasonable notice of any changes. By
        continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms.
      </p>
      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us.</p>
    </div>
  );
};

export default TermsOfService;
