import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { showToaster } from "../../../services/toastService";
import LabelsCreateDialog from "./LabelsCreateDialog";
import LabelsList from "./LabelsList";
import { AddBox } from "@mui/icons-material";
import { fetchLabels } from "../../../services/labelsApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { useQuery } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";

const labelsTypes = [
  { key: "lead", value: "Lead Label" },
  { key: "job", value: "Job Progress" },
];

export const getTitle = (type: string) => {
  const result = labelsTypes.find((item: any) => item.key == type);
  return result ? result?.value : "";
};

const LabelsEdit: React.FC = () => {
  const [labels, setLabels] = useState({} as any);
  const [selectedType, setSelectedType] = useState("lead");
  const [showModal, setShowModal] = useState(false);

  const { data, isError, error } = useQuery({
    queryKey: ["labels"],
    queryFn: () => fetchLabels(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isError) {
      showToaster(
        "Error fetching Labels " + formatErrorMessage(error.message),
        "error"
      );
    }
    setLabels(data?.data || []);
  }, [data, error]);

  function handleSelectChange(event: any): void {
    setSelectedType(event.target.value);
  }

  function handleCreate(event: any): void {
    setShowModal(true);
  }

  function handleOnSaved(label: any): void {
    console.log("label saved ", label);
    const newLabels = { ...labels };
    newLabels[selectedType].push(label);
    setLabels(newLabels);
  }

  function handleOnListUpdated(list: any[], type: string): void {
    const newLabels = { ...labels };
    newLabels[type] = list;
    setLabels(newLabels);
  }

  return (
    <>
      <ToastContainer />
      <LabelsCreateDialog
        label={null}
        type={selectedType}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="container">
        <BreadcrumbsComponent
          links={[{ to: "/settings", label: "Settings" }]}
          leaf="Labels"
        />
        <div className="page-title">Labels</div>
        <div className="row px-3 mt-3">
          <div className="col-auto mt-1">Type:</div>
          <div className="col-auto">
            <select className="form-control" onChange={handleSelectChange}>
              {labelsTypes.map((item) => {
                return (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary mr-5" onClick={handleCreate}>
              <AddBox className="mr-2" />
              {"Create " + getTitle(selectedType)}
            </button>
          </div>
        </div>

        <div className="row">
          <LabelsList
            labels={labels[selectedType]}
            type={selectedType}
            onListUpdated={handleOnListUpdated}
          />
        </div>
      </div>
    </>
  );
};

export default LabelsEdit;
