import React, { useEffect, useState } from "react";
import { arraysAreSimilar } from "../../../common/utils";
import { saveLeadLabels } from "../../../services/leadApiService";
import { Link } from "react-router-dom";
import { usePanelsContext } from "../../../context/PanelsContext";

interface Props {
  leadId: string;
  labels: any[];
  leadLabels: any[];
  updateLeadLabels: (labels: any[]) => void;
  setLoading: (loading: boolean) => void;
}

const LeadLabels: React.FC<Props> = (props) => {
  const { leadId, labels } = props;
  const [leadLabels, setLeadLabels] = useState<any[]>([]);
  const leadLabelIds = leadLabels?.map((label: any) => label.id);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const { panels, setPanelDisplay } = usePanelsContext();

  useEffect(() => {
    setLeadLabels(props.leadLabels);
  }, []);

  useEffect(() => {
    if (!leadLabels) return;
    checkItems(leadLabels);
  }, [leadLabels]);

  function handleClose() {
    setPanelDisplay("labels", false);
  }

  async function updateLabels(): Promise<void> {
    const checkedItemIds = getCheckedItemIds();
    console.log("applyLabels: ", checkedItemIds);

    if (!arraysAreSimilar(checkedItemIds, leadLabelIds)) {
      props.setLoading(true);
      console.log("updateLeadLabels: ", checkedItemIds);
      const data = {
        lead_id: leadId,
        label_ids: checkedItemIds,
      };

      try {
        const resp = await saveLeadLabels(leadId, data);
        console.log("labels updated successfully");
        props.updateLeadLabels(resp.data.labels);
        handleClose();
      } catch (err: any) {
        console.log("error updating labels: ", err);
      } finally {
        props.setLoading(false);
      }
    }
  }

  const handleChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  function getCheckedItemIds(): any[] {
    const checkedKeys = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    return checkedKeys;
  }

  function checkItems(leadLabels: any[]) {
    const checkedItems: any = {};
    for (const label of leadLabels) {
      checkedItems[label.id] = true;
    }
    setCheckedItems(checkedItems);
  }
  return (
    <div>
      <ul className="list-group">
        {labels.map((label: any) => (
          <li key={label.id} className="list-group-item">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  className="mr-3"
                  name={label.id}
                  checked={checkedItems[label.id]}
                  onChange={handleChange}
                />
                {label.name}
              </label>
            </div>
          </li>
        ))}
        <li key="divider" className="dropdown-divider"></li>
        <li key="apply" className="list-group-item">
          <div className="col-md-12 col-lg-12 text-center">
            <button className="btn btn-primary btn-sm" onClick={updateLabels}>
              Save
            </button>
          </div>
          <div className="col-md-12 col-lg-12 text-center mt-3">
            <Link className="btn btn-default btn-sm" to="/settings/labels">
              Manage Labels
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LeadLabels;
