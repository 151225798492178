import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { formatDate, formatDateTime } from "../../../common/dateUtils";
import { currencyFormat } from "../../../common/utils";
import {
  deleteInstallerPayment,
  fetchJobInstallerPayments,
  upsertInstallerPayment,
} from "../../../services/installerPaymentApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import ProgressSpinner from "../../common/ProgressSpinner";
import JobCustomerInfo from "../sidePane/JobCustomerInfo";
import { getNewPaymentData, getTotalTooltip } from "./installerPaymentService";
import { IInstallerPayment } from "./types";

const JobInstallerPayments: React.FC<any> = () => {
  const jobId = useParams().id;
  const [payments, setPayments] = useState<IInstallerPayment[]>([]);
  const [job, setJob] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobInstallerPayments(jobId).then((resp) => {
      console.log("installer payments: ", resp);
      if (resp.data) {
        setJob(resp.data.job);
        setPayments(resp.data.payments || []);
      } else {
        const error = resp.error || "no data";
        console.log("error: ", error);
      }
    });
  }, []);

  async function handleCreatePaymentClick(event: any) {
    console.log("handleCreatePaymentClick");
    setLoading(true);

    const data = getNewPaymentData(job);
    const resp = await upsertInstallerPayment(data);

    setLoading(false);
    console.log("resp: ", resp);
    if (resp.data?.id) {
      const newPayments = [...payments, resp.data];
      setPayments(newPayments);
      navigate(`/installer-payments/${resp.data.id}`);
    } else {
      const error = resp.error || "no data";
      toast.error(error);
    }
  }

  async function handleDeletePaymentClick(paymentId: any) {
    if (paymentId) {
      console.log("handleDeletePaymentClick " + paymentId);
      const payment = payments.find((d) => d.id == paymentId);
      const desc = payment?.description || "";
      const result = await Swal.fire({
        text: "Are you sure you want to delete this item?" + desc,
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (result.isConfirmed) {
        await handleDeletePayment(paymentId);
      }
    }
  }

  async function handleDeletePayment(paymentId: string) {
    const resp = await deleteInstallerPayment(paymentId);
    console.log("resp: ", resp);
    if (!resp.error) {
      const newPayments = payments.filter(
        (payment: IInstallerPayment) => payment.id != paymentId
      );
      setPayments(newPayments);
      toast.success("Payment deleted successfully");
    } else {
      const error = resp.error || "no data";
      toast.error(error);
    }
  }

  function getPaymentTotal(payment: IInstallerPayment, format = true) {
    const total = payment.lines.reduce(
      (acc, line) => acc + Number(line.total),
      0
    );
    if (!format) {
      return total;
    }
    return currencyFormat(total);
  }

  function getPaymentsTotal(payments: IInstallerPayment[]) {
    return payments.reduce(
      (acc, payment) => acc + Number(getPaymentTotal(payment, false)),
      0
    );
  }

  const breadcrumbsLinks = [{ to: `/jobs/${jobId}`, label: "Job" }];

  return (
    <div className="container">
      <ToastContainer />
      <ProgressSpinner show={loading} />
      <div className="m-1">
        <BreadcrumbsComponent
          links={breadcrumbsLinks}
          leaf="Installer Payments"
        />
      </div>
      <div className="row p-2 border">
        <div className="col-md-6">
          <JobCustomerInfo lead={job.lead} />
        </div>
        <div className="col">
          <div>
            <span className="text-strong">Job Title: </span>
            {job.name}
          </div>
          <div>
            <span className="text-strong">Job Number: </span>
            <Link className="text-decoration-none" to={`/jobs/${job.id}`}>
              {job.code ? job.code : job.id}
            </Link>
          </div>
          <div>
            <span className="text-strong">Payments: </span>
            {currencyFormat(getPaymentsTotal(payments))}
            <Tooltip title={getTotalTooltip(job, getPaymentsTotal(payments))}>
              <a href="#" className="btn btn-sm btn-outline-secondary ml-2">
                ?
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="row my-4">
        {payments.map((payment: IInstallerPayment) => {
          return (
            <div className="col mb-3" key={payment.id}>
              <div className="card" style={{ width: "34rem" }}>
                <div className="card-body">
                  <h5 className="card-title">
                    {payment.description || "<empty>"}
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Created At: {formatDateTime(payment.created_at + "")}
                  </h6>
                  <div className="card-text">
                    <span className="text-strong">Installer: </span>
                    {payment.installer}
                  </div>
                  <div className="card-text">
                    <span className="text-strong">Payment Date: </span>
                    {formatDate(payment.payment_date)}
                  </div>
                  <div className="card-text">
                    <span className="text-strong">Amount: </span>
                    {getPaymentTotal(payment)}
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row d-flex justify-content-end">
                    <Link
                      className="btn btn-primary btn-sm m-1 col-md-3"
                      to={`/installer-payments/${payment.id}`}
                    >
                      <BiEdit className="mr-1 mb-1" />
                      Edit
                    </Link>
                    <button
                      className="btn btn-danger btn-sm m-1 col-md-3"
                      onClick={() => handleDeletePaymentClick(payment.id)}
                    >
                      <BiTrash className="mr-1 mb-1" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row my-3">
        <div className="col">
          <button
            className="btn btn-secondary"
            onClick={handleCreatePaymentClick}
            disabled={loading}
          >
            Create New Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobInstallerPayments;
