import { change } from "@syncfusion/ej2-react-grids";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formatErrorMessage } from "../../common/utils";
import { showToaster } from "../../services/toastService";
import { changeUserPassword } from "../../services/userApiService";

interface IProps {
  show: boolean;
  user: any;
  onHide: (e?: any) => void;
}

const ChangePasswordDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm();

  useEffect(() => {
    setShowModal(props.show);
    reset(props.user);
  }, [props.show]);

  function handleCloseDialog(): void {
    props.onHide();
  }

  async function handleSavePassword(data: any) {
    if (!isDirty || isEmpty(data)) {
      return;
    }
    const resp = await changeUserPassword(data);
    if (resp.status === "success") {
      showToaster("Password changed");
      handleCloseDialog();
    } else {
      showToaster(
        "Password change failed: " + formatErrorMessage(resp.error),
        "error"
      );
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
      dialogClassName="modal-wide"
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="current_password"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Current Password
            </label>
            <div className="col-sm-8">
              <input
                type="password"
                className="form-control"
                {...register("current_password", {
                  required: "Password is required",
                })}
              />
              {errors.current_password && (
                <div className="invalid-feedback d-block">
                  {errors.current_password.message.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="new_password"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              New Password
            </label>
            <div className="col-sm-8">
              <input
                type="password"
                className="form-control"
                {...register("new_password", {
                  required: "New Password is required",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                    message:
                      "invalid password.\nit must have at least 6 characters, including an uppercase letter, a lowercase letter, and a number",
                  },
                })}
              />
              {errors.new_password && (
                <div className="invalid-feedback d-block">
                  {errors.new_password.message.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="new_password_confirmation"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Confirm Password
            </label>
            <div className="col-sm-8">
              <input
                type="password"
                className="form-control"
                {...register("new_password_confirmation", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === watch("new_password")
                      ? null
                      : "The passwords do not match",
                })}
              />
              {errors.new_password_confirmation && (
                <div className="invalid-feedback d-block">
                  {errors.new_password_confirmation.message.toString()}
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleSubmit(handleSavePassword)}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordDialog;
