import { APIProvider, Map } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { defaultMapCenter, googleMapId } from "../../../config/app";
import JobMarkers from "./JobMarkers";
import UserLocationMarker from "./UserLocationMarker";
import ServicesMarkers from "./ServicesMarkers";

interface IProps {
  markers: any[];
  apiKey: string;
}

const GoogleMapsView: React.FC<IProps> = (props) => {
  const [userLocation, setUserLocation] = useState(defaultMapCenter);

  useEffect(() => {
    getUsersLocation();
  }, []);

  function getUsersLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching the user's location", error);
        }
      );
    }
  }

  function handleContextMenuClick(event: any) {
    console.log("map clicked", event);
    const menu = document.getElementById("contextMenu");
    const display = menu.style.display === "block" ? "none" : "block";
    menu.style.left = event.domEvent?.offsetX + "px";
    menu.style.top = event.domEvent?.offsetY + "px";
    menu.style.display = display;
    menu.style.position = "absolute";
  }

  function handleMenuItemClick(e: any) {
    console.log("handleMenuItemClick", e);
  }

  function handleContextMenuClose(e: any) {
    const menu = document.getElementById("contextMenu");
    menu.style.display = "none";
  }

  return (
    <div id="map" className="map-container">
      <APIProvider apiKey={props.apiKey}>
        <Map
          defaultZoom={11}
          defaultCenter={userLocation}
          mapId={googleMapId}
          onContextmenu={handleContextMenuClick}
          onClick={handleContextMenuClose}
        >
          <UserLocationMarker position={userLocation} />
          <JobMarkers markers={props.markers} />
          <ServicesMarkers markers={props.markers} apiKey={props.apiKey} />
        </Map>
      </APIProvider>

      <div
        id="contextMenu"
        className="map-context-menu"
        onClick={handleContextMenuClose}
      >
        <div className="map-context-menu-item" onClick={handleMenuItemClick}>
          Action 1
        </div>
        <div className="map-context-menu-item" onClick={handleContextMenuClose}>
          Dismiss
        </div>
      </div>
    </div>
  );
};

export default GoogleMapsView;
