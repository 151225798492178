import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { isManager } from "../../../common/userUtils";
import {
  adaptiveCurrencyFormat,
  currencyFormat,
  currencyValue,
  formatErrorMessage,
} from "../../../common/utils";
import { updateJobLineItem } from "../../../services/jobLineItemService";
import { ILineItem } from "../types";
import { calculateLineTotal } from "./jobLineItemService";
import { useUserContext } from "../../../context/UserContext";
import { useMutation } from "@tanstack/react-query";

interface IProps {
  lineItem: ILineItem;
  show: boolean;
  onUpdated: (lineItem: ILineItem) => void;
  onHide?: () => void;
}

const JobLineItemEditDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lineItem, setLineItem] = useState<ILineItem>(null);
  const [user] = useUserContext();

  const { mutateAsync } = useMutation({
    mutationFn: updateJobLineItem,
  });

  useEffect(() => {
    setShowModal(props.show);
    reset(props.lineItem);
    setLineItem(props.lineItem);
  }, [props.show]);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  const handleUpdate = async (data: any) => {
    if (!isDirty) return;
    const itemId = props.lineItem.id;
    setLoading(true);

    try {
      const resp = await mutateAsync({ id: itemId, data: sanitizeData(data) });
      console.log("Updated line item: ", resp.data);
      props.onUpdated(resp.data);
      props.onHide();
      toast.success("Line item updated successfully");
    } catch (err: any) {
      const msg =
        "Error updating line item: " + formatErrorMessage(err.message);
      console.error(msg);
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const sanitizeData = (data: ILineItem) => {
    return {
      ...data,
      quantity: currencyValue(data.quantity),
      rate: currencyValue(data.rate),
      inflation_rate: currencyValue(data.inflation_rate),
      total: currencyValue(data.total),
    };
  };

  function handleQuantityRateBlur(e: any): void {
    const line = getValues();
    const quantity = line.quantity;
    const rate = line.rate;
    const total = calculateLineTotal(quantity, rate);

    setValue("rate", currencyFormat(rate));
    setValue("quantity", adaptiveCurrencyFormat(quantity));
    setValue("total", currencyFormat(total));
  }

  function handleLineTotalBlur(): void {
    const line = getValues();
    const total = currencyValue(line.total);
    const quantity = currencyValue(line.quantity);

    setValue("total", currencyFormat(total));

    if (quantity) {
      const rate = total / quantity;
      setValue("rate", currencyFormat(rate));
    }
  }

  function handleInflationRateBlur(): void {
    const line = getValues();
    const inflation = currencyValue(line.inflation_rate);
    const rate = currencyValue(line.rate);
    const quantity = currencyValue(line.quantity);
    const originalInflation = currencyValue(lineItem.inflation_rate);

    if (inflation !== originalInflation) {
      if (lineItem.rate && rate != 0) {
        const originalRate = currencyValue(lineItem.rate);
        const normalizedRate = originalRate / (1 + originalInflation / 100);
        const newRate = normalizedRate * (1 + inflation / 100);
        const newTotal = newRate * quantity;
        setValue("rate", currencyFormat(newRate));
        setValue("total", currencyFormat(newTotal));
      } else if (lineItem.total) {
        const originalTotal = currencyValue(lineItem.total);
        const normalizedTotal = originalTotal / (1 + originalInflation / 100);
        const newTotal = normalizedTotal * (1 + inflation / 100);
        setValue("total", currencyFormat(newTotal));
      }
    }

    setValue("inflation_rate", adaptiveCurrencyFormat(inflation));
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      variant="wider"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Line Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-2">
          <label className="mt-2">Description</label>
          <textarea
            className="form-control form-control-sm ml-2  line-item-description1"
            {...register("description")}
          ></textarea>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 mt-2 ml-2">Size</label>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              {...register("quantity", { onBlur: handleQuantityRateBlur })}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 mt-2 ml-2">Unit</label>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              spellCheck="false"
              {...register("unit", { required: true })}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <label className="col-3 mt-2 ml-2">Unit Price</label>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              autoComplete="off"
              {...register("rate", { onBlur: handleQuantityRateBlur })}
            />
          </div>
        </div>
        {isManager(user) && (
          <div className="form-group row mb-2">
            <label className="col-3 mt-2 ml-2">Inflation</label>
            <div className="col-8">
              <input
                type="text"
                className="form-control col-9"
                autoComplete="off"
                {...register("inflation_rate", {
                  onBlur: handleInflationRateBlur,
                })}
              />
            </div>
          </div>
        )}
        <div className="form-group row mb-2">
          <label className="col-3 mt-2 ml-2">Total</label>
          <div className="col-8">
            <input
              type="text"
              className="form-control col-8"
              {...register("total", {
                onBlur: handleLineTotalBlur,
              })}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading && <CircularProgress className="mr-5" />}
        <button className="btn btn-secondary" onClick={props.onHide}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit(handleUpdate)}
          disabled={!isDirty || loading}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobLineItemEditDialog;
