import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JobFeaturesCB from "./JobFeaturesCB";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  jobId: any;
  features: any[];
  jobFeatures: any[];
  updateJobFeatures: (features: any[]) => void;
  setLoading?: (loading: boolean) => void;
}

const JobFeaturesPane: React.FC<IProps> = (props) => {
  let { features, jobFeatures, jobId } = props;
  const { panels, setPanelDisplay } = usePanelsContext();

  function handleClosePane() {
    setPanelDisplay("features", false);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={panels.features}
        title="Features"
        width="40%"
        onRequestClose={handleClosePane}
      >
        <JobFeaturesCB
          features={features}
          jobId={jobId}
          jobFeatures={jobFeatures}
          closePane={handleClosePane}
          updateJobFeatures={(features: any) =>
            props.updateJobFeatures(features)
          }
          setLoading={(isLoading) => props.setLoading(isLoading)}
        />
      </SlidingPane>
    </div>
  );
};

export default JobFeaturesPane;
