import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Tooltip } from "@mui/material";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { formatDate } from "../../../common/dateUtils";
import {
  adaptiveCurrencyFormat,
  calculateTotal,
  currencyFormat,
  currencyValue,
} from "../../../common/utils";
import {
  fetchInstallerPayment,
  upsertInstallerPayment,
} from "../../../services/installerPaymentApiService";
import { showToaster } from "../../../services/toastService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import ProgressSpinner from "../../common/ProgressSpinner";
import JobCustomerInfo from "../sidePane/JobCustomerInfo";
import { formatErrorMessage } from "./../../../common/utils";
import InstallerPaymentLineEdit from "./InstallerPaymentLineEdit";
import { cleanData, getTotalTooltip } from "./installerPaymentService";
import { IInstallerPayment, IInstallerPaymentLine } from "./types";

const InstallerPaymentEdit: React.FC = () => {
  const paymentId = useParams().id;
  const [installerPayment, setInstallerPayment] = useState<IInstallerPayment>(
    {} as any
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<string>("");
  const [state, setState] = useState<any>({});

  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    control,
  } = useForm<IInstallerPayment>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "lines",
  });

  const paymentDateReg = register("payment_date");

  useEffect(() => {
    if (paymentId) {
      setLoading(true);
      fetchInstallerPayment(paymentId).then((response) => {
        setLoading(false);
        if (response.error) {
          console.log("error", response.error);
          setInstallerPayment(null);
          setState({ error: response.error });
          return;
        }
        processFetchedData(response.data);
      });
    }
  }, [paymentId]);

  function processFetchedData(data: IInstallerPayment) {
    if (!data) {
      return;
    }

    const lines = data.lines?.map((line: any) => {
      return {
        ...line,
        quantity: adaptiveCurrencyFormat(line.quantity),
        rate: currencyFormat(line.rate),
        total: currencyFormat(line.total),
      };
    });

    const _data = {
      ...data,
      payment_date: formatDate(data.payment_date),
      lines,
    };

    reset(_data);
    setInstallerPayment(_data);
    saveTotal(_data.lines);
  }

  function getTotal(lines: IInstallerPaymentLine[]): number {
    let total = 0;
    lines?.forEach((line: IInstallerPaymentLine) => {
      total += currencyValue(line.total);
    });
    return total;
  }

  function saveTotal(lines: IInstallerPaymentLine[]) {
    const total = getTotal(lines);
    setTotal(currencyFormat(total));
  }

  function handleAddLine() {
    const line: IInstallerPaymentLine = {
      id: null,
      display_order: 0,
      description: "",
      unit: "",
      quantity: "0",
      rate: "0",
      total: "0",
    };
    append(line);
  }

  async function updateInstallerPayment(data: IInstallerPayment) {
    if (!formState.isDirty || isEmpty(data)) {
      return;
    }
    console.log("updateInstallerPayment", data);
    setLoading(true);
    const resp = await upsertInstallerPayment(cleanData(data));
    if (resp.data) {
      showToaster("Saved Successfully");
      processFetchedData(resp.data);
    } else if (resp.error) {
      console.log("error", resp.error);
      showToaster("Error: " + resp.error, "error");
    }
    setLoading(false);
  }

  function recalculateTotal(e: any, index: number): any {
    console.log("getValues", getValues());
    saveTotal(getValues().lines);
  }

  function handleQuantityRateBlur(event: any, index: number) {
    const line = getValues().lines[index];
    const quantity = line.quantity;
    const rate = line.rate;
    const lineTotal = calculateTotal(quantity, rate);

    setValue(`lines.${index}.rate`, currencyFormat(rate));
    setValue(`lines.${index}.quantity`, adaptiveCurrencyFormat(quantity));
    setValue(`lines.${index}.total`, currencyFormat(lineTotal));

    saveTotal(getValues().lines);
  }

  function handleLineTotalBlur(e: any, index: number): void {
    const line = getValues().lines[index];
    const total = currencyValue(line.total);
    const quantity = currencyValue(line.quantity);

    setValue(`lines.${index}.total`, currencyFormat(total));

    if (quantity) {
      const rate = total / quantity;
      setValue(`lines.${index}.rate`, currencyFormat(rate));
    }
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    if (location.state) {
      navigate("/installer-payments", { state: location.state });
    } else {
      navigate(-1);
    }
  }

  const breadcrumbsLinks = [
    { to: `/jobs/${installerPayment.job?.id}`, label: "Job" },
    {
      to: `/jobs/${installerPayment.job?.id}/installer-payments`,
      label: "Installer Payments",
    },
  ];

  if (state.error) {
    return (
      <div className="row m-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{formatErrorMessage(state.error)}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="m-1">
          <BreadcrumbsComponent
            links={breadcrumbsLinks}
            leaf="Installer Payment"
          />
        </div>
        <div className="fixed-header">
          <div className="d-flex">
            {location.state && (
              <div className="mr-2">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleGoBack}
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </div>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Fab
                color="primary"
                variant="extended"
                className="mx-2"
                disabled={!formState.isDirty}
                onClick={handleSubmit(updateInstallerPayment)}
              >
                <SaveIcon className="mr-1" />
                Save
              </Fab>
            </Box>
          </div>
        </div>
        <ProgressSpinner show={loading} />
        <ToastContainer />
        <div>
          <div className="row p-3 border my-2">
            <div className="col-md-6">
              <JobCustomerInfo lead={installerPayment.job?.lead} />
            </div>
            <div className="col">
              <div>
                <span className="text-strong">Job Title: </span>
                {installerPayment.job?.name}
              </div>
              <div>
                <span className="text-strong">Job Number: </span>
                <Link
                  to={`/jobs/${installerPayment.job?.id}`}
                  className="text-decoration-none"
                >
                  {installerPayment.job?.code
                    ? installerPayment.job?.code
                    : installerPayment.job?.id}
                </Link>
                <span className="">
                  {getTotalTooltip(installerPayment.job, total)}
                </span>
              </div>
              <div>
                <span className="text-strong">Total: </span>
                {currencyFormat(total)}
                {/* <Tooltip title={getTotalTooltip(installerPayment.job, total)}>
                  <a href="#" className="btn btn-sm btn-outline-secondary ml-2">
                    ?
                  </a>
                </Tooltip> */}
              </div>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-start align-items-end">
              <div className="text-muted">
                {getTotalTooltip(installerPayment.job, total)}
              </div>
            </div>
          </div>

          <Paper elevation={3}>
            <div className="supplier my-3 mx-2 p-3">
              <div className="row mb-1">
                <div className="col-md-3">
                  <label htmlFor="orderedby" className="form-label">
                    Installer
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("installer")}
                    placeholder="John"
                    defaultValue={installerPayment.installer}
                  />
                </div>
                <div className="col">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("description")}
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="payment_date" className="form-label">
                    Payment Date
                  </label>
                  <DatePickerComponent
                    format="MMM d, yyyy"
                    name={paymentDateReg.name}
                    onChange={paymentDateReg.onChange}
                    onBlur={paymentDateReg.onBlur}
                    ref={paymentDateReg.ref}
                  />
                </div>
              </div>
            </div>
          </Paper>
          <div className="mb-1 mt-4 border d-none d-md-block">
            <div className="px-4 pt-2 pb-2 m-2">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="description" className="fw-bold">
                    Description
                  </label>
                </div>
                <div className="col-md-2">
                  <label htmlFor="quantity" className="fw-bold">
                    Quantity
                  </label>
                </div>
                <div className="col-md-2">
                  <label htmlFor="rate" className="fw-bold">
                    Rate
                  </label>
                </div>
                <div className="col-md-2">
                  <label htmlFor="total" className="fw-bold">
                    Total
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            {fields.map((line: any, index: number) => (
              <InstallerPaymentLineEdit
                key={line.id}
                index={index}
                register={register}
                deleteItem={remove}
                handleTotalChange={recalculateTotal}
                handleTotalBlur={handleLineTotalBlur}
                handleQuantityRateBlur={handleQuantityRateBlur}
              />
            ))}
          </div>
          <div className="row mt-3 mb-5 px-5">
            <button
              className="btn btn-outline-secondary"
              onClick={handleAddLine}
            >
              <AddIcon className="mr-2" />
              Add Line
            </button>
          </div>
          <div className="mb-5 mt-3 border p-3">
            <div className="row">
              <div className="col-md-6">
                <label className="fw-bold mb-2">Total:</label>
              </div>
              <div className="col-md-6">
                <div className="input-group ">
                  <span className="input-group-text bg-secondary-subtle">
                    $
                  </span>
                  <input
                    type="text"
                    name="line-items-sum"
                    className="form-control disabled"
                    value={total}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallerPaymentEdit;
