import Pagination from "@mui/material/Pagination";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  Inject,
  Resize,
  Sort,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { formatErrorMessage, parseQueryStringToJSON } from "../../common/utils";
import { fetchFormFields, fetchJobs } from "../../services/jobApiService";
import ProgressSpinner from "../common/ProgressSpinner";
import { IMeta, IPageQuery } from "../common/types";
import { invalidateQueries } from "../../common/dataUtils";
import { isEmpty } from "lodash";

interface IRowData {
  id: number;
  [key: string]: any;
}

const JobsDataGrid: React.FC = () => {
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({} as IPageQuery);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [soldOnly, setSoldOnly] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = new QueryClient();

  useEffect(() => {
    const queryJson = parseQueryStringToJSON();
    let _params = { ...params, ...queryJson };

    if (location.state) {
      _params = location.state;
      setSearch(_params.query || "");
      setSoldOnly(_params.state === "sold");
    } else if ("sold_month" in queryJson || isEmpty(queryJson)) {
      setSoldOnly(true);
      _params = { ..._params, state: "sold" };
    }
    setParams(_params);
    setPageLoaded(true);
  }, []);

  const { data: formData } = useQuery({
    queryKey: ["jobs-filter"],
    queryFn: fetchFormFields,
    staleTime: Infinity,
  });

  const {
    data: jobsData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["jobs", { params }],
    queryFn: () => fetchJobs(params),
    staleTime: Infinity,
    enabled: pageLoaded,
    retry: 1,
  });

  let grid: Grid | null;

  const rowSelected = () => {
    if (grid) {
      const selected: any[] = grid.getSelectedRecords();
      const id = selected[0].id;
      const options = { state: { ...params } };
      navigate(`/jobs/${id}`, options);
    }
  };

  const pagination: IMeta = jobsData?.meta || ({} as IMeta);
  const filterOptions = formData?.data.labels || [];

  function handleOnChange(event: any): void {
    setSearch(event.target.value);
  }

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  function handleSearchClick() {
    handleSearch();
  }

  async function handleSearch() {
    if (search || params) {
      const filter = {
        ...params,
        query: search,
        ...parseQueryStringToJSON(),
      };
      setParams(filter);
    }
  }

  async function handlePaginationChange(event: any, page: number) {
    if (page == pagination.current_page) {
      return;
    }

    const queryString = parseQueryStringToJSON();
    const _params: IPageQuery = {
      page: page,
      query: pagination.query,
      sort_by: pagination.sort_by,
      sort_direction: pagination.sort_direction,
      size: pagination.per_page,
      state: params["state"],
      ...queryString,
    };

    setParams(_params);
  }

  function handleSwitchChange(event: any): void {
    console.log("switch changed manually");
    const state = event.target.checked ? "sold" : "";
    setSoldOnly(event.target.checked);
    setParams({ ...params, state });
  }

  function handleFilterChange(e: any): void {
    const status = e.target.value;
    const filter: IPageQuery = {
      ...params,
      ...parseQueryStringToJSON(),
      status,
      page: 1,
    };
    console.log("filterChanged", filter);
    setParams(filter);
  }

  function handleClearClick(): void {
    invalidateQueries(queryClient, ["jobs"]);
    setSearch("");
    const queryString = parseQueryStringToJSON();
    const params: any = {
      ...queryString,
      query: "",
    };
    setParams(params);
    setSoldOnly(false);
  }

  if (isError) {
    return (
      <div className="row m-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{formatErrorMessage(error.message)}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="p-3">
        <ProgressSpinner show={isLoading} />
        <div className="page-title">Jobs List</div>
        <div className="row mb-3 mt-3">
          <div className="col-md-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={search}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleSearchClick}
                >
                  Search
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleClearClick}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Sold Jobs Only"
                className="mt-2"
                onChange={handleSwitchChange}
                checked={soldOnly}
              />
            </Form>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6">
            <Pagination
              count={pagination.last_page}
              page={pagination.current_page || 1}
              onChange={handlePaginationChange}
              shape="rounded"
            />
          </div>
          <div className="col">
            <select
              className="form-select"
              aria-label="status filter"
              onChange={handleFilterChange}
              value={params?.status || 0}
            >
              <option key="0" value="0">
                Filter by...
              </option>
              {filterOptions?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col d-flex justify-content-end mt-2 text-secondary">
            jobs found: <span className="ml-1"> {pagination.total}</span>
          </div>
        </div>
        <div className="row mb-3" style={{ minWidth: 1000 }}>
          <GridComponent
            dataSource={jobsData?.data}
            allowSorting={true}
            allowResizing={true}
            rowSelected={rowSelected}
            ref={(g) => (grid = g)}
          >
            <Inject services={[Resize, Sort]} />
            <ColumnsDirective>
              <ColumnDirective field="code" headerText="Number" width={92} />
              <ColumnDirective
                field="lead.customer_name"
                headerText="Customer"
              />
              <ColumnDirective field="name" headerText="Title" />
              <ColumnDirective field="lead.phone" headerText="Phone" />
              <ColumnDirective field="lead.street" headerText="Address" />
              <ColumnDirective field="lead.city" headerText="City" />
            </ColumnsDirective>
            <Inject services={[Sort]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default JobsDataGrid;
