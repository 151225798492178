import AddIcon from "@mui/icons-material/Add";
import arrayMove from "array-move";
import { isEmpty } from "lodash";
import React, { useEffect, useImperativeHandle, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useFieldArray, useForm } from "react-hook-form";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
import { isManager } from "../../../common/userUtils";
import {
  adaptiveCurrencyFormat,
  calculateTotal,
  currencyFormat,
  currencyValue,
  formatErrorMessage,
} from "../../../common/utils";
import { useJobContext } from "../../../context/JobContext";
import { useUserContext } from "../../../context/UserContext";
import {
  addJobLineItem,
  deleteJobLineItem,
  deleteJobLineItems,
  updateJobLineItems,
} from "../../../services/jobLineItemService";
import ProgressSpinner from "../../common/ProgressSpinner";
import { ILineItem } from "../types";
import JobLineItem from "./JobLineItem";
import JobLineItemEditDialog from "./JobLineItemEditDialog";
import { addInflationRate } from "./jobLineItemService";
import { invalidateQueries } from "../../../common/dataUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePanelsContext } from "../../../context/PanelsContext";
import { toast } from "react-toastify";

interface RefType {
  saveLineItems: () => void;
}

interface IProps {
  job: any;
  setLoading: (loading: boolean) => void;
  onTotalUpdated: (totalAmount: string, totalSize: string) => void;
}

const JobLineItems: React.ForwardRefRenderFunction<RefType, IProps> = (
  props,
  ref
) => {
  const job = props.job;
  const [totalSum, setTotalSum] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortChanged, setSortChanged] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedLineItem, setSelectedLineItem] = useState<ILineItem>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);
  const [setIsLineDirty] = useJobContext();
  const [user] = useUserContext();

  const { setPanelDisplay } = usePanelsContext();

  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isPendingUpdate } = useMutation({
    mutationFn: updateJobLineItems,
  });

  useImperativeHandle(ref, () => ({
    saveLineItems: handleSaveLineItems,
    reset: handleReset,
  }));

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, dirtyFields },
    setValue,
    getValues,
    control,
  } = useForm();

  const { fields, move } = useFieldArray({
    control,
    name: "lineItems",
  });

  useEffect(() => {
    processFetchedData(job.line_items);
  }, [job.line_items]);

  useEffect(() => {
    //todo: debounce line update
    console.log("JobLineItems sortChanged: ", sortChanged);
    console.log("JobLineItems isDirty: ", isDirty);
    console.log("JobLineItems dirtyFields: ", dirtyFields);
    setIsLineDirty(sortChanged || isDirty);
  }, [sortChanged, isDirty]);

  function getSortedLineItems(lineItems: ILineItem[]) {
    const sortedLineItems = lineItems?.sort((a: any, b: any) => {
      return a.sort_index - b.sort_index;
    });
    return sortedLineItems || [];
  }

  async function deleteLineItem(itemId: any) {
    setLoading(true);

    try {
      await deleteJobLineItem(itemId);
      toast.success("Line item deleted successfully");
      invalidateQueries(queryClient, ["job"]);
    } catch (err: any) {
      const message = err.message;
      const _message = `Failed to delete line item: ${message}`;
      toast.error(_message);
    } finally {
      setLoading(false);
    }
  }

  function removeItemFromList(itemId: any) {
    const _lineItems = getValues("lineItems").filter(
      (item: any) => item.id !== itemId
    );
    processFetchedData(_lineItems);
  }

  function addItemToList(lineItem: ILineItem) {
    const _lineItems = getValues("lineItems");
    _lineItems.push(lineItem);
    processFetchedData(_lineItems);
  }

  function getTotal(lines: ILineItem[]): number {
    let total = 0;
    lines?.forEach((line: ILineItem) => {
      total += currencyValue(line.total);
    });
    return total;
  }

  function getTotalSize(lines: ILineItem[]): number {
    const quantities = lines?.map((line) => currencyValue(line.quantity));
    const uniqueQuantities = Array.from(new Set(quantities));
    const total =
      uniqueQuantities?.reduce((total, quantity) => (total += quantity), 0) ||
      0;
    return total;
  }

  function saveTotal(lines: ILineItem[]) {
    const totalAmount = getTotal(lines);
    const totalAmountStr = currencyFormat(totalAmount);

    const totalSize = getTotalSize(lines);
    const totalSizeStr = adaptiveCurrencyFormat(totalSize);

    setTotalSum(totalAmountStr);

    props.onTotalUpdated(totalAmountStr, totalSizeStr);
  }

  function handleQuantityRateBlur(
    event: React.FocusEvent<HTMLInputElement>,
    lineIndex: number
  ) {
    const line = getValues(`lineItems.${lineIndex}`);
    const quantity = line.quantity;
    const rate = line.rate;
    const total = calculateTotal(quantity, rate);

    setValue(`lineItems.${lineIndex}.rate`, currencyFormat(rate));
    setValue(
      `lineItems.${lineIndex}.quantity`,
      adaptiveCurrencyFormat(quantity)
    );
    setValue(`lineItems.${lineIndex}.total`, currencyFormat(total));

    saveTotal(getValues().lineItems);
  }

  function handleLineTotalBlur(e: any, lineIndex: number): void {
    const line = getValues(`lineItems.${lineIndex}`);
    const total = currencyValue(line.total);
    const quantity = currencyValue(line.quantity);

    setValue(`lineItems.${lineIndex}.total`, currencyFormat(total));

    if (quantity) {
      const rate = total / quantity;
      setValue(`lineItems.${lineIndex}.rate`, currencyFormat(rate));
    }

    saveTotal(getValues().lineItems);
  }

  const maxSortIndex = getValues("lineItems")?.reduce(
    (max: number, lineItem: ILineItem) => {
      return lineItem.sort_index > max ? lineItem.sort_index : max;
    },
    0
  );

  const addNewLineItem = async () => {
    const result = await Swal.fire({
      text: "Are you sure you want to add a new line?",
      position: "top",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Add",
      confirmButtonColor: "#0D6EFD",
    });

    if (!result.isConfirmed) {
      return;
    }

    handleSaveLineItems();

    props.setLoading(true);
    const payload = {
      sort_index: maxSortIndex + 1,
      id: "",
      job_id: job.id,
      description: "",
    };
    const resp = await addJobLineItem(payload);
    if (resp.data) {
      addItemToList(resp.data);
    } else {
      const message = resp.error?.message || resp.error;
      toast.error(
        "Error trying to add line item" + formatErrorMessage(message)
      );
      console.log("line item add failed: ", message);
    }
    props.setLoading(false);
  };

  const updateLineItems = async () => {
    const lineItemsUpdate = getValues("lineItems");
    const lines = lineItemsUpdate?.map((lineItem: any) =>
      cleanupLineItem(lineItem)
    );

    if (isEmpty(lines)) {
      console.log("no changes");
    }

    try {
      const resp = await mutateAsync(lines);
      console.log("resp: ", resp);
      setSortChanged(false);
      toast.success("Line items updated successfully");
      processFetchedData(resp.data);
    } catch (err: any) {
      toast.error(
        "Error on line items update" + formatErrorMessage(err.message)
      );
    }
  };

  function cleanupLineItem(lineItem: any) {
    lineItem.job_id = job.id;

    if (lineItem.quantity) {
      lineItem.quantity = currencyValue(lineItem.quantity);
    }
    if (lineItem.rate) {
      lineItem.rate = currencyValue(lineItem.rate);
    }
    if (lineItem.total) {
      lineItem.total = currencyValue(lineItem.total);
    }
    return lineItem;
  }

  function handleSaveLineItems(): void {
    console.log("handleSaveLineItems");
    console.log("dirtyFields", dirtyFields);

    if (dirtyFields.lineItems || sortChanged) {
      handleSubmit(updateLineItems)();
    }
  }

  function handleReset() {
    processFetchedData(job.line_items);
    setSortChanged(false);
    checkIsAnyRowSelected();
  }

  function processFetchedData(data: ILineItem[]) {
    if (!data) {
      return;
    }

    let lineItems = data?.map((line: any) => {
      if (!line.total) {
        line.total = Number(line.rate || 0) * Number(line.quantity || 0);
      }
      return {
        ...line,
        quantity: adaptiveCurrencyFormat(line.quantity),
        rate: currencyFormat(line.rate),
        total: currencyFormat(line.total),
        inflation_rate: adaptiveCurrencyFormat(line.inflation_rate),
        selected: false,
      };
    });

    lineItems = getSortedLineItems(lineItems);
    reset({ lineItems });
    saveTotal(lineItems);
  }

  const updateLineItemsSortIndex = () => {
    const updated = getValues("lineItems")?.map(
      (lineItem: any, index: number) => {
        lineItem.sort_index = index;
        return lineItem;
      }
    );
    return updated;
  };

  const handleDeleteLineItem = async (index: number) => {
    const id = getValues(`lineItems.${index}.id`);

    const result = await Swal.fire({
      text: "Are you sure you want to delete line?",

      position: "top",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Delete",
    });

    if (result.isConfirmed) {
      deleteLineItem(id);
    }
  };

  const handleEditLineItem = (index: ILineItem) => {
    const lineItem = getValues(`lineItems.${index}`);
    setSelectedLineItem(lineItem);
    setShowEditModal(true);
  };

  const handleOnHide = () => {
    setShowEditModal(false);
  };

  const handleOnUpdated = (lineItem: ILineItem) => {
    console.log("handleOnUpdated: ", lineItem);
    const lineItems = getValues("lineItems");
    const _lineItem = {
      ...lineItem,
      quantity: adaptiveCurrencyFormat(lineItem.quantity),
      inflation_rate: adaptiveCurrencyFormat(lineItem.inflation_rate),
      rate: currencyFormat(lineItem.rate),
      total: currencyFormat(lineItem.total),
    };

    const updatedLineItems = lineItems.map((item: ILineItem) =>
      item.id === lineItem.id ? _lineItem : item
    );

    processFetchedData(updatedLineItems);
  };

  function handleOnSortEnd(oldIndex: number, newIndex: number): void {
    const lineItems = getValues("lineItems");
    const sortedLineItems = arrayMove(lineItems, oldIndex, newIndex);
    reset({ lineItems: sortedLineItems });
    updateLineItemsSortIndex();
    setSortChanged(true);
  }

  function handleSelectAll(e: any): void {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    const lineItems = getValues("lineItems");
    const updatedLineItems = lineItems.map((item: ILineItem) => ({
      ...item,
      selected: isChecked,
    }));
    reset({ lineItems: updatedLineItems });
    checkIsAnyRowSelected();
  }

  function handleCheckboxChanged(index: number, checked: boolean): void {
    setValue(`lineItems.${index}.selected`, checked);
    checkIsAnyRowSelected();
  }

  async function handleBulkUpdate(event: any): Promise<void> {
    event.preventDefault();

    const inflation = await showPrompt();

    if (!inflation) return;

    const lineItems = getValues("lineItems");
    lineItems.forEach((item: ILineItem, index: number) => {
      if (item.selected) {
        const updatedItem = addInflationRate(item, Number(inflation));
        setValue(`lineItems.${index}`, updatedItem, { shouldDirty: true });
      }
    });
    saveTotal(getValues().lineItems);
  }

  async function showPrompt() {
    const { value } = await Swal.fire({
      position: "top",
      input: "text",
      inputLabel: "Please enter the inflating amount (%):",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "a number between 0 and 100 is required";
        }
      },
    });
    return value;
  }

  async function handleBulkRemove(event: any): Promise<void> {
    event.preventDefault();
    console.log("handleBulkRemove");
    const lineItems = getValues("lineItems");
    const ids = lineItems
      .filter((item: ILineItem) => item.selected)
      .map((item: ILineItem) => item.id);

    if (ids.length === 0) {
      toast.error("No line items selected");
      return;
    }
    setLoading(true);

    try {
      await deleteJobLineItems(ids);
      toast.success("Line item deleted successfully");
      // ids.forEach((itemId: any) => removeItemFromList(itemId));
      // saveTotal(getValues().lineItems);
      invalidateQueries(queryClient, ["job"]);
    } catch (err: any) {
      const message =
        "Failed to delete line items: " + formatErrorMessage(err.message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  }

  function checkIsAnyRowSelected() {
    const lineItems = getValues("lineItems");
    const selected = lineItems.filter((item: any) => item.selected == true);
    const anySelected = selected.length > 0;
    setIsAnyRowSelected(anySelected);
    if (!anySelected) setSelectAll(false);
  }

  const BulkActionsButton = () => (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Bulk Actions
      </button>
      <ul className="dropdown-menu">
        {isManager(user) && (
          <li>
            <a className="dropdown-item" href="#" onClick={handleBulkUpdate}>
              <BsPencilSquare className="mr-2 mb-1" />
              Inflate Price
            </a>
          </li>
        )}
        <li>
          <a className="dropdown-item" href="#" onClick={handleBulkRemove}>
            <BsTrash className="mr-2 mb-1" />
            Remove
          </a>
        </li>
      </ul>
    </div>
  );
  const TotalColumn = () => <div>Total</div>;

  function openTemplatePanel() {
    setPanelDisplay("templates", true);
    setPanelDisplay("showNotes", false);
  }

  function openNotesPanel() {
    setPanelDisplay("notes", true);
  }

  return (
    <div>
      <JobLineItemEditDialog
        show={showEditModal}
        lineItem={selectedLineItem}
        onUpdated={handleOnUpdated}
        onHide={handleOnHide}
      />
      <ProgressSpinner show={loading || isPendingUpdate} />
      <div className="row my-5">
        <div className="line-items-panel">
          <div className="input-group mb-3">
            <div className="mr-3">Line Items:</div>
            <button
              className="btn btn-sm btn-outline-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Add New Lines From
            </button>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={openTemplatePanel}
                >
                  Templates
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={openNotesPanel}>
                  Proposal Notes
                </a>
              </li>
            </ul>
          </div>

          <div className="">
            <div className="line-items-th">
              <div className="header"></div>
              <div className="header">Description</div>
              <div className="header">Size</div>
              <div className="header">Unit</div>
              <div className="header">Unit Price</div>
              <div className="header">
                {isAnyRowSelected ? <BulkActionsButton /> : <TotalColumn />}
              </div>
              <div className="header">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </div>
            </div>

            <SortableList
              onSortEnd={handleOnSortEnd}
              className="sort-list"
              draggedItemClassName="line-item-dragged"
            >
              {fields.map((item: any, index: number) => (
                <SortableItem key={item.id}>
                  <div>
                    <JobLineItem
                      key={item.id}
                      index={index}
                      register={register}
                      lineItem={item}
                      handleQuantityRateBlur={handleQuantityRateBlur}
                      deleteLineItem={() => handleDeleteLineItem(index)}
                      handleTotalBlur={handleLineTotalBlur}
                      editLineItem={handleEditLineItem}
                      onCheckboxChanged={handleCheckboxChanged}
                    />
                  </div>
                </SortableItem>
              ))}
            </SortableList>

            <div className="row mx-5 mt-3">
              <button
                className="btn btn-outlined-secondary mb-5"
                onClick={addNewLineItem}
                disabled={job.read_only}
              >
                <AddIcon className="mr-2" />
                Add New Line Item
              </button>
            </div>
          </div>
        </div>
        <div className="row position-relative">
          <div className="col-sm-9 ml-5">Total</div>
          <div className="col-sm-3 position-absolute end-0">
            <div className="input-group mb-3">
              <span className="input-group-text bg-secondary-subtle">$</span>
              <input
                type="text"
                name="line-items-sum"
                className="form-control disabled"
                value={totalSum}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(JobLineItems);
