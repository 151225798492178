import { convertHtmlToText, currencyValue } from "../../common/utils";
import { v4 as uuidv4 } from "uuid";

interface LineTotalResult {
  total: number;
  size: number;
  unit: string;
}

function extractSum(
  regex: RegExp,
  line: string,
  multiplier = 1
): { sum: number; unit: string } {
  let matches;
  let sum = 0;
  let unit = "";
  while ((matches = regex.exec(line)) !== null) {
    const {
      groups: { sign, value },
    } = matches;
    try {
      const numValue = currencyValue(value);
      sum += numValue * (sign ? -1 : 1) * multiplier;
    } catch (error) {
      console.error(`Error parsing float from ${value}: ${error}`);
    }
    unit = matches.groups?.unit || "";
  }
  return { sum, unit };
}

export function extractLineTotal(line: string): LineTotalResult {
  const totalRE = /(?<sign>-)?\$(?<value>\d+[\d\.,]*)\b/gi;
  const { sum: total } = extractSum(totalRE, line);

  const sizeRE =
    /(?<value>\d+[,.\d+]*)'?\s*\.?(?<unit>sqf\b|s\.?\/?f\b|(sq\.?\/?|square)\s*(ft\.?|feet))/gi;
  const linearSizeRE =
    /(?<value>\d+[,.\d+]*)'?\s*(l\.?\/?f\b|(ln\.*|lin\.*|linear)\s*(ft\.*|feet))/gi;

  const sizeResult = extractSum(sizeRE, line);
  const linearSizeResult = extractSum(linearSizeRE, line, 0.75);
  const size = sizeResult.sum + linearSizeResult.sum;
  const unit = sizeResult.unit || linearSizeResult.unit;

  return {
    total,
    size,
    unit,
  };
}

export function extractLineItems(proposalNote: string) {
  const proposalText = convertHtmlToText(proposalNote);
  let [description, rest] = proposalText.split(":\n", 2);

  // If there was no ":\n" in the text
  if (rest === undefined) {
    rest = description;
    description = "";
  }

  const lines = rest.split(".\n");
  const linesResult = [];

  for (let line of lines) {
    line = line.trim();
    if (line) {
      const result = extractLineTotal(line);
      if (result.total || result.size) {
        linesResult.push({
          id: uuidv4(),

          description: line,
          total: result.total,
          quantity: result.size,
          unit: result.unit,
        });
      }
    }
  }
  if (description && linesResult.length > 0) {
    linesResult.unshift({
      id: uuidv4(),
      description,
      total: 0,
      quantity: 0,
      unit: "",
    });
  }
  return linesResult;
}
