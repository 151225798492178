import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, registerables, InteractionItem } from "chart.js";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { fetchReports } from "../../services/reportApiService";
import {
  NumberToMonth,
  adaptiveCurrencyFormat,
  getCurrentMonthAmount,
  getCurrentMonthCount,
  getLastMonths,
  getLocalUtcDifference,
  getMonthlyCount,
  monthToNumber,
} from "../../common/utils";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toLower } from "lodash";
import AppointmentList from "./AppointmentList";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import packageJson from "../../../package.json";

ChartJS.register(...registerables);

const Home: React.FC = () => {
  const chartRef: any = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState<any>({});
  const [appointments, setAppointments] = useState<any>([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Jobs Sold",
        data: [],
        type: "bar" as const,
        backgroundColor: "#3d7fff",
        borderColor: "#3d7fff55",
        borderWidth: 1,
        borderRadius: 3,
      },
      {
        label: "Leads",
        data: [],
        type: "line" as const,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.8)",
        borderWidth: 1,
      },
    ],
  });

  const { data, isError, error } = useQuery({
    queryKey: ["reports"],
    queryFn: fetchReports,
    staleTime: 1000 * 60 * 5,
  });

  function handleOnError(err: any) {
    console.error("Error occurred:", err.message);
    setState({ error: err.message });
  }

  useEffect(() => {
    processData(data);
    if (isError) {
      handleOnError(error);
    }
  }, [data, error]);

  const chartOptions: any = {
    plugins: {
      title: {
        display: true,
        text: "Jobs Sold",
        align: "start",
        font: {
          size: 18,
          weight: "bold",
        },
      },
      subtitle: {
        display: true,
        align: "start",
        text: "Last 12 Months",
        font: {
          size: 12,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  function processData(resp: any) {
    if (resp?.data) {
      const jobs = resp.data.jobs || [];
      const leads = resp.data.leads || [];
      const appts = resp.data.appointments || [];
      setAppointmentsData(appts);

      const _chartData = { ...chartData };
      _chartData.labels = jobs.map((item: any) => {
        return NumberToMonth(item.month);
      });
      _chartData.datasets[0].data = getMonthlyCount(jobs);
      _chartData.datasets[1].data = getMonthlyCount(leads);
      setChartData(_chartData);

      setState({
        currentJobs: getCurrentMonthCount(jobs),
        currentLeads: getCurrentMonthCount(leads),
        currentJobsAmount: getCurrentMonthAmount(jobs),
      });
    }
  }

  function handleClick(event: any): void {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const elem = getEventElement(chart, event);
    if (elem?.datasetIndex === 0) {
      navigate("/jobs?sold_month=" + elem.value);
    }
    if (elem?.datasetIndex === 1) {
      navigate("/leads?created_month=" + elem.value);
    }
  }

  const getEventElement = (chart: any, event: any) => {
    const element: InteractionItem[] = getElementAtEvent(chart, event);

    if (!element.length) return {};

    const { datasetIndex, index } = element[0];
    const label = toLower(chartData.labels[index]);
    const lastMonths = getLastMonths();
    const selectedMonth = lastMonths.find(
      (item: any) => item.month == monthToNumber(label)
    );

    return {
      datasetIndex,
      label,
      value: selectedMonth?.year + "-" + selectedMonth?.month,
    };
  };

  const setAppointmentsData = (data: any) => {
    var hoursDiff = getLocalUtcDifference();
    const appts = data.map((item: any) => {
      return {
        title: item.customer_name,
        // start: addHours(item.appointment, hoursDiff),
        start: new Date(item.appointment),
        backgroundColor: "red",
        borderColor: "green",
        extendedProps: {
          status: "done",
          id: item.id,
        },
      };
    });
    setAppointments(appts);
  };

  if (state.error) {
    return (
      <div className="p-1">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{state.error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3">
      <div className="row m-3">
        <Paper elevation={3} className="mr-3 dashboard-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Jobs Sold</div>
              <div className="card-subtitle mb-2">This Month</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(state.currentJobsAmount)}
              </div>
            </div>
            <div className="card-text">
              <Link
                to={"/jobs?sold_month=" + DateTime.now().toFormat("yyyy-M")}
                className="text-decoration-none"
              >
                {state.currentJobs}
              </Link>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="dashboard-card-small">
          <div className="dashboard-card-body">
            <div className="card-title">Leads</div>
            <div className="card-subtitle">This Month</div>
            <div className="card-text">
              <Link
                to={"/leads?created_month=" + DateTime.now().toFormat("yyyy-M")}
                className="text-decoration-none"
              >
                {state.currentLeads}
              </Link>
            </div>
          </div>
        </Paper>
      </div>
      <div className="row m-3 mt-5">
        <Paper elevation={3} className="dashboard-card-medium">
          <div className="dashboard-card-body">
            <Chart
              ref={chartRef}
              key={Math.random()}
              data={chartData}
              type="bar"
              options={chartOptions}
              onClick={handleClick}
            />
          </div>
        </Paper>
      </div>
      <div className="row m-3 mt-5">
        <Paper elevation={3} className="dashboard-card-medium">
          <div className="dashboard-card-body">
            <div className="card-title">Appointments</div>
            <div className="card-subtitle mb-3">Next 7 Days</div>
            <AppointmentList events={appointments} />
          </div>
        </Paper>
      </div>

      <footer className="d-flex flex-wrap justify-content-between align-items-center border-top mt-5 py-1">
        <span className="col-md-4 mb-0 text-body-secondary">
          Version {packageJson.version}
        </span>
        <span className="col-md-4 mb-0 text-body-secondary">
          © 2023 Team Pavers
        </span>
        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <Link to="/terms" className="nav-link px-2 text-body-secondary">
              Terms of Service
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/privacy" className="nav-link px-2 text-body-secondary">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Home;
