import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, login, saveAuthToken } from "../../auth/auth";
import classNames from "classnames";
import { apiUrl } from "../../config/app";

type Inputs = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const location = useLocation();
  const originLocation = location.state?.pathname || "/";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const forgotPasswordUrl = `${apiUrl}/forgot-password`.replace("/api", "");

  useEffect(() => {
    if (isLoggedIn) {
      navigate(originLocation);
    }
    if (isAuthenticated()) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
    setError("");
    const result = await login(data);
    if (result.error) {
      setError(result.error);
      return;
    }
    if (result.data.access_token) {
      saveAuthToken(result.data);
      setIsLoggedIn(true);
    }
  };

  const submitButtonClass = classNames("mb-3", {
    disabled: errors.email || errors.password,
  });

  return (
    <div className="login-page">
      <div className="login-form-b mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Login</h1>
          <label htmlFor="username">Email:</label>
          <input
            id="username"
            type="email"
            className="form-control mb-3 mt-2"
            {...register("email")}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            className="form-control mb-3 mt-2"
            {...register("password")}
          />
          <div className="text-danger">{error}</div>
          <div className="mb-3">
            <a className="text-decoration-none" href={forgotPasswordUrl}>
              Forgot Password?
            </a>
          </div>
          <input type="submit" value="Login" className={submitButtonClass} />
        </form>
      </div>
    </div>
  );
};

export default Login;
