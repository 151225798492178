import React from "react";
import { Link } from "react-router-dom";
import { googlePlaceUrl } from "../../../config/app";

const JobCustomerInfo: React.FC<any> = (props) => {
  const lead = props.lead || {};
  const addrArray = [lead.street, lead.city, lead.state, lead.zip];
  return (
    <div style={{ fontSize: "10pt" }}>
      <div>
        <span className="text-strong">Customer Name:</span>
        <Link to={`/leads/${lead?.id}`} className="text-decoration-none ml-2">
          {lead?.customer_name}
        </Link>
      </div>
      <div>
        <span className="text-strong">Phone:</span>
        <a href={`tel:${lead?.phone}`} className="ml-2 text-decoration-none">
          {lead?.phone}
        </a>
      </div>
      <div>
        <span className="text-strong mr-2">Email:</span>
        <a href={`mailto:${lead?.email}`} className="ml-1 text-decoration-none">
          {lead?.email}
        </a>
      </div>
      <div>
        <span className="text-strong">Address:</span>
        <a
          href={googlePlaceUrl + addrArray.join("+")}
          className="text-decoration-none ml-2"
          target="_blank"
          rel="noreferrer"
        >
          {addrArray.join(", ")}
        </a>
      </div>
    </div>
  );
};

export default JobCustomerInfo;
