import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import LinkRouter from "./LinkRouter";

interface IProps {
  links: ILink[];
  leaf: string;
}

interface ILink {
  to: string;
  label: string;
}

const BreadcrumbsComponent: React.FC<IProps> = ({ links, leaf }) => (
  <Breadcrumbs aria-label="breadcrumb" className="m-1">
    {links.map((item: ILink) => (
      <LinkRouter
        key={item.label}
        underline="hover"
        color="inherit"
        to={item.to}
      >
        {item.label}
      </LinkRouter>
    ))}
    <Typography color="text.primary">{leaf}</Typography>
  </Breadcrumbs>
);

export default BreadcrumbsComponent;
