import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formatErrorMessage } from "../../../common/utils";
import { showToaster } from "../../../services/toastService";
import { IPrice } from "./types";
import { upsertPrice } from "../../../services/pricesApiService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IProps {
  price: IPrice;
  type: string;
  show: boolean;
  onHide: () => void;
}

const fieldNameValidation = {
  required: "field is required",
  minLength: {
    value: 2,
    message: "min length 2",
  },
};

const fieldPriceValidation = {
  required: "field is required",
  pattern: {
    value: /^[0-9\.]*$/,
    message: "field must be a number",
  },
};

const PricesCreateDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const typeDeposit = "deposit";
  const isDeposit = props.type == typeDeposit;

  const { mutateAsync } = useMutation({
    mutationFn: upsertPrice,
  });
  const queryClient = useQueryClient();

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(props.price);
    let _title = props.price?.id ? "Edit " : "Create ";
    _title += "Item Price";
    setTitle(_title);
    setShowModal(props.show);
  }, [props.show]);

  function handleCloseDialog(): void {
    setShowModal(false);
    props.onHide();
  }

  const onSubmit = (data: any) => {
    console.log("onSubmit", data);
  };

  async function handleSavePrice() {
    await handleSubmit(onSubmit)();
    if (Object.keys(errors).length || !isDirty) return;
    const data = getValues();

    handleCloseDialog();

    try {
      const resp = await mutateAsync({ data, isDeposit });
      queryClient.invalidateQueries({ queryKey: ["prices"] });
      const name = isDeposit ? resp.data.vendor : resp.data.name;
      showToaster("Price saved: " + name);
    } catch (error: any) {
      showToaster(
        "Error saving Price: " + formatErrorMessage(error.message),
        "error"
      );
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          {isDeposit && (
            <div>
              <div className="mb-3 row">
                <label
                  htmlFor="vendor"
                  className="col-sm-3 col-form-price text-end fw-semibold"
                >
                  Supplier
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    {...register("vendor", fieldNameValidation)}
                  />
                  {errors.vendor && (
                    <span className="text-danger">
                      {errors.vendor.message.toString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="price"
                  className="col-sm-3 col-form-price text-end fw-semibold"
                >
                  Price
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="9.99"
                    {...register("price", fieldPriceValidation)}
                  />
                  {errors.price && (
                    <span className="text-danger">
                      {errors.price.message.toString()}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          {!isDeposit && (
            <div>
              <div className="mb-3 row">
                <label
                  htmlFor="name"
                  className="col-sm-3 col-form-price text-end fw-semibold"
                >
                  Item
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    {...register("name", fieldNameValidation)}
                  />
                  {errors.name && (
                    <span className="text-danger">
                      {errors.name.message.toString()}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="value"
                  className="col-sm-3 col-form-price text-end fw-semibold"
                >
                  Price
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    {...register("value", fieldPriceValidation)}
                  />
                  {errors.value && (
                    <span className="text-danger">
                      {errors.value.message.toString()}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSavePrice} disabled={!isDirty}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PricesCreateDialog;
