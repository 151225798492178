import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchJobPavers = async (jobId: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/jobs/${jobId}/pavers`);
    console.log("job pavers fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchJobPavers " + err);
    return { error: err.message };
  }
};

export const addJobPavers = async (jobId: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + `/jobs/${jobId}/pavers`, data);
    console.log("job pavers added ");
    return resp;
  } catch (err: any) {
    console.log("error on addJobPavers " + err);
    return { error: err.message };
  }
};

export const updateJobPavers = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + `/jobs/pavers/${data.id}`, data);
    console.log("job pavers(s) updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updateJobPavers " + err);
    return { error: err.message };
  }
};

export const deleteJobPavers = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + `/jobs/pavers/${id}`);
    console.log("job pavers deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteJobPavers " + err);
    return { error: err.message };
  }
};

export const fetchPaverOrder = async (orderId: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/paver-orders/${orderId}`);
    console.log("paver order fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchPaverOrder " + err);
    return { error: err.message };
  }
};

export const createPaverOrder = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + `/paver-orders`, data);
    console.log("paver order added ");
    return resp;
  } catch (err: any) {
    console.log("error on addPaverOrders " + err);
    return { error: err.message };
  }
};

export const updatePaverOrder = async (orderId: any, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + `/paver-orders/${orderId}`,
      data
    );
    console.log("paver order updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updatePaverOrder " + err);
    return { error: err.message };
  }
};

export const deletePaverOrder = async (orderId: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + `/paver-orders/${orderId}`);
    console.log("paver order deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deletePaverOrder " + err);
    return { error: err.message };
  }
};

export const deletePaverOrderItem = async (itemId: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(
      apiUrl + `/paver-orders/items/${itemId}`
    );
    console.log("paver order item deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deletePaverOrderItem " + err);
    return { error: err.message };
  }
};

export const createPaverOrderDelivery = async (orderId: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(
      apiUrl + `/paver-orders/${orderId}/deliveries`,
      data
    );
    console.log("paver order delivery added ");
    return resp;
  } catch (err: any) {
    console.log("error on createPaverOrderDelivery " + err);
    return { error: err.message };
  }
};
