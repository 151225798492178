import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formatErrorMessage, toTitleCaseSingle } from "../../../common/utils";
import { upsertFormInput } from "../../../services/formInputsApiService";
import { showToaster } from "../../../services/toastService";
import { IFormInput, IFormInputParams } from "./types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invalidateQueries } from "../../../common/dataUtils";

interface IProps {
  show: boolean;
  formInput: IFormInput;
  type: string;
  onHide: (e?: any) => void;
  onSaved: (formInput: IFormInput) => void;
}

const FormInputsCreateDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(props.formInput);
    let _title = props.formInput?.id ? "Edit " : "Create ";
    _title += toTitleCaseSingle(props.type);
    setTitle(_title);
    setShowModal(props.show);
  }, [props.show]);

  const { mutateAsync } = useMutation({
    mutationFn: upsertFormInput,
  });
  const queryClient = useQueryClient();

  function handleCloseDialog(event: any = null): void {
    setShowModal(false);
    props.onHide();
  }

  async function handleSaveFormInput(data: any) {
    if (!isEmpty(errors) || !isDirty) return;

    try {
      const params: IFormInputParams = {
        type: props.type,
        data: data,
      };
      const resp = await mutateAsync(params);
      handleCloseDialog();
      invalidateQueries(queryClient, [
        "form-inputs",
        "job-form-fields",
        "form-fields",
      ]);
      showToaster(toTitleCaseSingle(props.type) + " saved: " + resp.data.name);
    } catch (err: any) {
      showToaster(
        "Error saving " +
          toTitleCaseSingle(props.type) +
          " " +
          formatErrorMessage(err.message),
        "error"
      );
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Title
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Joe Doe"
                {...register("name", { required: true, minLength: 2 })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-danger">This is required</span>
              )}
              {errors.name && errors.name.type === "minLength" && (
                <span className="text-danger">Min length 2</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="active"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Status
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("active")}>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleSubmit(handleSaveFormInput)}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormInputsCreateDialog;
