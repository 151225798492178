import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

interface ConfirmDialogProps {
  show: boolean;
  title?: string;
  message: string;
  onHide: (e?: any) => void;
  handleDeleteClick: () => void;
  closeButtonText?: string;
  primaryButtonText?: string;
  primaryButtonVariant?: string;
  hidePrimaryButton?: boolean;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleCancelClick = () => {
    setShowModal(false);
    props.onHide();
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title ? props.title : "Confirm"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelClick}>
          {props.closeButtonText ?? "Cancel"}
        </Button>
        {!props.hidePrimaryButton && (
          <Button
            variant={props.primaryButtonVariant ?? "danger"}
            onClick={props.handleDeleteClick}
          >
            {props.primaryButtonText ?? "Delete"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
