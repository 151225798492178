import { AddBox } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import {
  formatErrorMessage,
  toTitleCase,
  toTitleCaseSingle,
} from "../../../common/utils";
import { fetchFormInputs } from "../../../services/formInputsApiService";
import { showToaster } from "../../../services/toastService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import FormInputsCreateDialog from "./FormInputsCreateDialog";
import FormInputsList from "./FormInputsList";

const FormInputs: React.FC = () => {
  const [formInputs, setFormInputs] = useState({} as any);
  const [selectedType, setSelectedType] = useState("sources");
  const [showModal, setShowModal] = useState(false);

  const { data, isError, error } = useQuery({
    queryKey: ["form-inputs"],
    queryFn: () => fetchFormInputs(),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data) {
      processData(data);
    }
    if (isError) {
      handleOnError(error);
    }
  }, [data, error]);

  function handleOnError(error: any): void {
    console.log("error on fetchFormInputs ", error);
    showToaster(
      "Error fetching formInputs " + formatErrorMessage(error.message),
      "error"
    );
  }

  function processData(resp: any): void {
    console.log("formInputs fetched ", resp.data?.length);
    setFormInputs(resp.data || []);
  }

  function handleSelectChange(event: any): void {
    setSelectedType(event.target.value);
  }

  function handleCreate(event: any): void {
    setShowModal(true);
  }

  function handleOnSaved(formInput: any): void {
    console.log("formInput saved ", formInput);
    const newFormInputs = { ...formInputs };
    newFormInputs[selectedType].push(formInput);
    setFormInputs(newFormInputs);
  }

  function handleOnListUpdated(list: any[], type: string): void {
    const newFormInputs = { ...formInputs };
    newFormInputs[type] = list;
    setFormInputs(newFormInputs);
  }

  return (
    <>
      <ToastContainer />
      <FormInputsCreateDialog
        formInput={null}
        type={selectedType}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="container">
        <BreadcrumbsComponent
          links={[{ to: "/settings", label: "Settings" }]}
          leaf="Form Inputs"
        />
        <div className="page-title">Form Inputs</div>
        <div className="row px-3 mt-3">
          <div className="col-auto mt-1">Type:</div>
          <div className="col-auto">
            <select className="form-control" onChange={handleSelectChange}>
              {Object.keys(formInputs).map((key: string) => (
                <option value={key}>{toTitleCase(key)}</option>
              ))}
            </select>
          </div>
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary mr-5" onClick={handleCreate}>
              <AddBox className="mr-2" />
              {"Create " + toTitleCaseSingle(selectedType)}
            </button>
          </div>
        </div>

        <div className="row">
          <FormInputsList
            formInputs={formInputs[selectedType]}
            type={selectedType}
            onListUpdated={handleOnListUpdated}
          />
        </div>
      </div>
    </>
  );
};

export default FormInputs;
