import React from "react";
import { constants } from "../../../config/constants";
import { deleteEmail } from "../../../services/emailApiService";
import ConfirmDialog from "../../common/ConfirmDialog";

interface Props {
  email: any;
  onDeleteEmail: (id: string) => void;
}

const EmailDisplay: React.FC<Props> = (props) => {
  const { email, onDeleteEmail } = props;
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const sendEmail = () => {
    window.location.href = `mailto:${email.email}`;
  };

  const handleDeleteEmailClick = (data: any = {}) => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  async function handleDeleteEmail() {
    handleCancelDelete();
    const resp = await deleteEmail(email.id);
    if (resp.status == constants.SUCCESS_STATUS) {
      onDeleteEmail(email.id);
    } else {
      console.log(
        "Error deleting email " + JSON.stringify(resp.error),
        "error"
      );
    }
  }

  return (
    <>
      <ConfirmDialog
        show={showDeleteModal}
        message={`Are you sure you want to delete email: '${email.label}'?`}
        onHide={handleCancelDelete}
        handleDeleteClick={handleDeleteEmail}
      />
      <div className="input-group mt-2">
        <input
          type="email"
          className="form-control"
          aria-label="email"
          readOnly
          value={email.email}
        />
        <div className="btn-group">
          <span className="input-group-text" id="basic-addon2">
            {email.label ?? "other"}
          </span>
          <button className="btn btn-default" type="button" onClick={sendEmail}>
            <i className="bi bi-envelope-fill"></i>
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={handleDeleteEmailClick}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default EmailDisplay;
