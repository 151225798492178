import { Paper } from "@mui/material";
import React from "react";
import { BiTrash } from "react-icons/bi";

interface IProps {
  register: any;
  index: number;
  deleteItem: (id: number) => void;
  handleTotalBlur?: (e: any, index: number) => void;
  handleTotalChange?: (e: any, index: number) => void;
  handleQuantityRateBlur?: (e: any, index: number) => void;
}

const InstallerPaymentLineEdit: React.FC<IProps> = (props) => {
  const { register, deleteItem, index } = props;

  function handleDelete(event: any): void {
    event.preventDefault();
    deleteItem(index);
  }

  function handleQuantityRateBlur(event: any): void {
    if (props.handleQuantityRateBlur) {
      props.handleQuantityRateBlur(event, index);
    }
  }

  function handleTotalChange(event: any): void {
    if (props.handleTotalChange) {
      props.handleTotalChange(event, index);
    }
  }

  function handleTotalBlur(event: any): void {
    if (props.handleTotalBlur) {
      props.handleTotalBlur(event, index);
    }
  }

  return (
    <div className="mb-3">
      <Paper>
        <div className="p-4 m-2">
          <div className="row">
            <div className="col-md-6">
              <label className="d-md-none form-label">Description</label>
              <input
                type="text"
                className="form-control"
                {...register(`lines.${index}.description`)}
                placeholder="Description"
              />
            </div>
            <div className="col-md-2">
              <label className="d-md-none form-label mt-2">Size</label>
              <input
                type="text"
                className="form-control"
                {...register(`lines.${index}.quantity`, {
                  onBlur: handleQuantityRateBlur,
                })}
              />
            </div>
            <div className="col-md-2">
              <label className="d-md-none form-label mt-2">Rate</label>
              <input
                type="text"
                className="form-control"
                {...register(`lines.${index}.rate`, {
                  onBlur: handleQuantityRateBlur,
                })}
              />
            </div>
            <div className="col-md-2">
              <label className="d-md-none form-label mt-2">Total</label>
              <div className="input-group" id="">
                <input
                  type="text"
                  className="form-control"
                  {...register(`lines.${index}.total`, {
                    onChange: handleTotalChange,
                    onBlur: handleTotalBlur,
                  })}
                />
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={handleDelete}
                >
                  <BiTrash />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default InstallerPaymentLineEdit;
