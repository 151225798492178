import { createContext, useContext } from "react";

type IPanelsContext = {
  panels: any;
  setPanelDisplay: (name: IPanelName, isVisible: boolean) => void;
};

export type IPanelName =
  | "labels"
  | "checklist"
  | "progress"
  | "features"
  | "templates"
  | "notes"
  | "showNotes";

const defaultPanelsContext: IPanelsContext = {
  panels: {},
  setPanelDisplay: () => {},
};

export const PanelsContext =
  createContext<IPanelsContext>(defaultPanelsContext);

export function usePanelsContext() {
  const { panels, setPanelDisplay } = useContext(PanelsContext);
  return { panels, setPanelDisplay };
}
