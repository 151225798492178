import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JobLabelsCB from "./JobLabelsCB";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  jobId: any;
  labels: any[];
  jobLabels: any[];
  updateJobLabels: (labels: any[]) => void;
  setLoading?: (loading: boolean) => void;
}

const JobLabelsPane: React.FC<IProps> = (props) => {
  const { jobLabels, labels, jobId } = props;
  const { panels, setPanelDisplay } = usePanelsContext();

  function handleClosePane() {
    setPanelDisplay("progress", false);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={panels.progress}
        title="Job Progress"
        width="40%"
        onRequestClose={handleClosePane}
      >
        <JobLabelsCB
          labels={labels}
          jobId={jobId}
          jobLabels={jobLabels}
          updateJobLabels={(labels) => props.updateJobLabels(labels)}
          setLoading={(isLoading) => props.setLoading(isLoading)}
        />
      </SlidingPane>
    </div>
  );
};

export default JobLabelsPane;
