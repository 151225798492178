const useLocal = false;

export const baseUrl = !useLocal
  ? "https://api.teampavers.com"
  : "http://localhost";

export const apiUrl = baseUrl + "/api";

// dev
export const authUrl = "https://api.teampavers.com/api/auth";
// prod
// export const authUrl = apiUrl + "/auth";
export const tinyApiKey = "zyjtm2rv35l3p5mwklzrn7vye1394a0if59pc3zhd0gftl4m";

export const googleApiKey = "AIzaSyCdNB-m9pL4de5uS-pqxel4GgdwaAjRul0";

export const googlePlaceUrl = "https://www.google.com/maps/place/";

export const defaultMapCenter = {
  lat: 40.2338,
  lng: -111.6585,
};

export const googleMapId = "70cff98488e65a0e";

export const defaultRep = "Sam";
