import React from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/app";
import { useUserContext } from "../../../context/UserContext";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";

interface Props {
  name?: string;
}

const IntegrationsList: React.FC<Props> = () => {
  const breadcrumbLinks = [{ to: `/settings`, label: "Settings" }];
  const [user] = useUserContext();

  const hasIntegration = (name: string) => {
    return user?.integrations?.find((d: any) => d.name === name);
  };

  return (
    <div className="container">
      <BreadcrumbsComponent links={breadcrumbLinks} leaf="Integrations" />
      <div className="p-3">
        <div className="settings-list-integrations">
          <div className="page-title">Integrations</div>
          <div className="settings-list-integrations-grid p-3">
            <div className="card" style={{ width: "18rem" }}>
              <div className="integration-card-header ">
                <img
                  src={baseUrl + "/images/quickbooks_logo_horz.png"}
                  className="card-img-top"
                  alt="quickbooks"
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">Quickbooks Online</h5>
                <p className="card-text">
                  Connect your Quickbooks Online account to Leads.
                </p>
                <Link
                  className="text-decoration-none"
                  to="/integrations/quickbooks"
                >
                  {hasIntegration("quickbooks") ? "Update" : "Connect"}
                </Link>
              </div>
            </div>
            <div className="card" style={{ width: "18rem" }}>
              <div className="integration-card-header ">
                <img
                  src={baseUrl + "/images/google_calendar_logo.png"}
                  className="card-img-top mx-auto"
                  alt="google-calendar"
                  style={{ width: "120px" }}
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">Google Calendar</h5>
                <p className="card-text">
                  Connect your Google Calendar account to Leads.
                </p>
                <Link
                  className="text-decoration-none"
                  to="/integrations/google-calendar"
                >
                  {hasIntegration("google") ? "Update" : "Connect"}
                </Link>
              </div>
            </div>
            <div className="card" style={{ width: "18rem" }}>
              <div className="integration-card-header ">
                <img
                  src={baseUrl + "/images/outlook-logo.png"}
                  className="card-img-top mx-auto"
                  alt="outlook-calendar"
                  style={{ width: "120px" }}
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">Outlook</h5>
                <p className="card-text">
                  Connect your Outlook Calendar account to Leads.
                </p>
                <Link
                  className="text-decoration-none"
                  to="/integrations/outlook"
                >
                  {hasIntegration("outlook") ? "Update" : "Connect"}
                </Link>
              </div>
            </div>
            <div className="card" style={{ width: "18rem" }}>
              <div className="integration-card-header ">
                <img
                  src={baseUrl + "/images/google-maps-logo.png"}
                  className="card-img-top mx-auto"
                  alt="google-maps"
                  style={{ width: "120px" }}
                />
              </div>
              <div className="card-body">
                <h5 className="card-title">Google Maps</h5>
                <p className="card-text">
                  Connect your Google Maps account to Leads.
                </p>
                <Link
                  className="text-decoration-none"
                  to="/integrations/google-maps"
                >
                  {hasIntegration("google-maps") ? "Update" : "Connect"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsList;
