import React, { useEffect, useState } from "react";
import {
  deleteIntegration,
  fetchAuthUrl,
  fetchIntegrations,
  updateIntegration,
} from "../../../services/integrationsApiService";
import { IIntegration } from "../types";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { formatDateTime } from "../../../common/dateUtils";
import { fetchCalendars } from "../../../services/calendarApiService";
import { showToaster } from "../../../services/toastService";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { isValidEmail } from "../../../common/utils";
import { FiX } from "react-icons/fi";
import { ToastContainer } from "react-toastify";

const integrationName = "google";

const GoogleCalendarSettings: React.FC = () => {
  const [state, setState] = useState<any>({});
  const [integration, setIntegration] = useState<IIntegration>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    fetchIntegrations(integrationName).then((res) => {
      setState({ ...state, activeTab: "settings" });
      if (res.data[0]) {
        const integration = res.data[0];
        setIntegration(integration);
        reset(integration.settings);
      }
    });
  }, []);

  async function connectToGoogle(e: any) {
    e.preventDefault();
    const resp = await fetchAuthUrl(integrationName);
    if (resp.data.url) {
      window.location.href = resp.data.url;
    }
  }

  const settingsTabPaneClass = classNames("mt-3 tab-pane fade", {
    "show active": state?.activeTab == "settings",
    "d-none": state?.activeTab != "settings",
  });

  const debugTabPaneClass = classNames("mt-3 tab-pane fade", {
    "show active": state?.activeTab == "debug",
    "d-none": state?.activeTab != "debug",
  });

  const settingsTabClass = classNames("nav-link", {
    active: state?.activeTab == "settings",
  });

  const debugTabClass = classNames("nav-link", {
    active: state?.activeTab == "debug",
  });

  function showTab(event: any): void {
    event.preventDefault();
    setState({ ...state, activeTab: event.target.id });
  }

  async function handleDeleteIntegration(e: any) {
    e.preventDefault();
    if (integration.id) {
      console.log("handleDeleteIntegration " + integration.id);
      const result = await Swal.fire({
        text: "Are you sure you want to delete this integration?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (result.isConfirmed) {
        const resp = await deleteIntegration(integration.id);
        if (resp.status == "success") {
          setIntegration(null);
        }
      }
    }
  }

  async function handleSaveIntegration(e: any) {
    e.preventDefault();
    if (isDirty && integration.id) {
      setLoading(true);
      const values = getValues();
      console.log("handleSaveIntegration " + integration.id);

      const resp = await updateIntegration(integration.id, values);
      if (resp.data) {
        const integration = resp.data;
        setIntegration(integration);
        reset(integration.settings);
        showToaster("Integration saved successfully.");
      }
      setLoading(false);
    }
  }

  async function getCalendars(event: any) {
    event.preventDefault();
    setLoading(true);
    const resp = await fetchCalendars(integrationName);
    if (resp.data) {
      const settings = { ...integration.settings, calendars: resp.data };
      setIntegration({ ...integration, settings });
    } else {
      showToaster("Failed to get calendars." + resp.message, "error");
    }
    setLoading(false);
  }

  const breadcrumbLinks = [
    { label: "Settings", to: "/settings" },
    { label: "Integrations", to: "/settings/integrations" },
  ];

  const notifyEmailsArray = watch("notifyEmails")?.split(";");

  function handleDeleteEmail(event: any, email: string): void {
    event.preventDefault();
    const emails = notifyEmailsArray.filter((e: any) => e !== email);
    const emailString = emails.length > 0 ? emails.join(";") : null;
    setValue("notifyEmails", emailString, { shouldDirty: true });
  }

  function handleAddEmail(): void {
    const emails = new Set(notifyEmailsArray || []);
    const email = getValues("notifyEmail");

    if (emails.has(email)) {
      updateState({ notifyEmailError: "Email already exists" });
      return;
    }
    if (!isValidEmail(email)) {
      updateState({ notifyEmailError: "Invalid email address" });
      return;
    }

    emails.add(email);
    setValue("notifyEmails", Array.from(emails).join(";"));
    setValue("notifyEmail", "");
    updateState({ notifyEmailError: "" });
  }

  function updateState(newState: any): any {
    setState((prevState: any) => ({
      ...prevState,
      ...newState,
    }));
  }

  return (
    <>
      <ToastContainer />
      <div className="container">
        <BreadcrumbsComponent links={breadcrumbLinks} leaf="Google Calendar" />
        <h3>Google Calendar Integration</h3>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={settingsTabClass}
              aria-current="page"
              href="#"
              id="settings"
              onClick={showTab}
            >
              Settings
            </a>
          </li>
          <li className="nav-item">
            <a className={debugTabClass} href="#" id="debug" onClick={showTab}>
              Debug Info
            </a>
          </li>
        </ul>
        <div className={settingsTabPaneClass}>
          <div className="ml-3 my-3 d-flex justify-content-start">
            <a
              className="btn btn-outline-secondary btn-sm"
              href="#"
              onClick={connectToGoogle}
            >
              {!integration?.is_active
                ? "Connect to Google"
                : "Renew Connection"}
            </a>
          </div>
          {state?.calendars && (
            <div className="mx-3">
              <div className="mb-2">
                <span className="text-strong">Calendars:</span>
                <ul>
                  {state?.calendars.map((calendar: any) => (
                    <li className="list-unstyled" key={calendar.id}>
                      {calendar.summary}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {integration && (
            <div className="mx-3">
              <div className="mb-2">
                <span className="text-strong">Status:</span>{" "}
                {integration.is_active ? "Active" : "Inactive"}
                <a
                  className="ml-3 text-decoration-none"
                  href="#"
                  onClick={handleDeleteIntegration}
                >
                  Disconnect
                </a>
              </div>
              {!integration?.settings?.calendarId && (
                <div className="alert alert-warning mt-3" role="alert">
                  Please select a calendar. Use the "Get Calendars" button to
                  get a list of calendars.
                </div>
              )}
              <div className="row mb-2">
                <div className="col-sm-2 text-strong">Calendar Name:</div>
                <div className="col-sm-4">
                  <div className="input-group">
                    <select
                      className="form-control form-control-sm"
                      defaultValue={integration?.settings?.calendarId}
                      {...register("calendarId")}
                    >
                      <option value="">Select Calendar</option>
                      {integration?.settings?.calendars?.map(
                        (calendar: any) => (
                          <option key={calendar.id} value={calendar.id}>
                            {calendar.name}
                          </option>
                        )
                      )}
                    </select>
                    <button
                      className="btn btn-secondary btn-sm ml-3"
                      onClick={getCalendars}
                    >
                      Get Calendars
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-2 mt-5">
                <div className="text-strong">
                  Emails to receive notifications:
                </div>
                <div className="row col-auto mt-2">
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      {...register("notifyEmail")}
                    />
                    <button
                      className="btn btn-secondary btn-sm ml-2"
                      onClick={handleAddEmail}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className=" mt-2">
                  <small className="text-danger">
                    {state.notifyEmailError}
                  </small>
                </div>
                <ul className="m-1 list-unstyled">
                  {notifyEmailsArray?.map((email: string, index: number) => (
                    <li key={index} className="text-decoration-none">
                      {email}
                      <a
                        href="#"
                        className="btn btn-sm text-danger mb-1"
                        onClick={(e) => handleDeleteEmail(e, email)}
                        title="Remove email"
                      >
                        <FiX size={20} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="row mb-2">
                <div className="col-sm-6 mt-4 text-center">
                  <button
                    className="btn btn-primary btn-sm mr-5"
                    onClick={handleSaveIntegration}
                    disabled={!isDirty}
                  >
                    Save
                  </button>
                </div>
                <div className="col-2">
                  {loading && <CircularProgress className="mr-5" />}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={debugTabPaneClass}>
          <div style={{ margin: "40px 10px" }}>
            <div>
              <label className="text-strong">Access Token</label>
              <textarea
                className="form-control form-control-sm mt-2"
                style={{ height: "148px" }}
                readOnly={true}
                value={integration?.access_token}
              ></textarea>
            </div>
            <div className="mt-1 text-sm">
              <label className="text-strong">Expires at: </label>
              <span className="ml-2 mt-1 text-secondary">
                {formatDateTime(integration?.expires_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleCalendarSettings;
