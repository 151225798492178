import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Input } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

interface ConfirmDialogProps {
  show: boolean;
  quantity: number;
  onHide: (e?: any) => void;
  handleSaveClick: (data: any) => void;
}

const DeliveryAddDialog = (props: ConfirmDialogProps) => {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({} as any);

  useEffect(() => {
    setShowModal(props.show);
    setState({
      qty: props.quantity,
      delivered_at: new Date(),
    });
  }, [props.show]);

  const handleSaveClick = () => {
    setShowModal(false);
    props.onHide();
    props.handleSaveClick(state);
  };

  const handleCancelClick = () => {
    setShowModal(false);
    props.onHide();
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      className="modal-dialog-layer"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter order delivery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-3">
          <div className="row my-2">
            <div className="col-2 mt-1">
              <label>Quantity</label>
            </div>
            <div className="col-6">
              <Input name="qty" value={state.qty} onChange={handleOnChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-2 mt-1">
              <label>Date</label>
            </div>
            <div className="col-6">
              <DatePickerComponent
                name="delivered_at"
                onChange={handleOnChange}
                value={state.delivered_at}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSaveClick}>
          Save
        </Button>
        <Button variant="danger" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeliveryAddDialog;
