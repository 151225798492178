import { Paper } from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { formatErrorMessage } from "../../common/utils";
import { constants } from "../../config/constants";
import {
  addRepair,
  deleteRepair,
  fetchJobRepairs,
} from "../../services/repairApiService";
import ProgressSpinner from "../common/ProgressSpinner";
import JobCustomerInfo from "../job/sidePane/JobCustomerInfo";
import JobRepairsBreadCrumbs from "./JobRepairsBreadCrumbs";
import RepairCreateDialog from "./RepairCreateDialog";
import { IJobRepair } from "./types";
import { useUserContext } from "../../context/UserContext";

const JobRepairList: React.FC = () => {
  const jobId = useParams().id;
  const [job, setJob] = useState<IJobRepair>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [user] = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await fetchJobRepairs(jobId);
      if (resp.data) {
        setJob(resp.data);
      } else {
        setError(resp.error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  function dateFormat(date: string) {
    if (!date) return "None";
    return DateTime.fromISO(date).toFormat("MMM dd, yyyy");
  }

  function handleViewPDF(repairId: any): void {
    navigate(`/repairs/${repairId}/pdf`);
  }

  function handleEditRepair(repairId: any): void {
    navigate(`/repairs/${repairId}`);
  }

  async function handleDeleteRepair(repairId: any) {
    const dialog = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this repair!",
      showCancelButton: true,
      confirmButtonColor: constants.COLOR_DANGER,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (dialog.isConfirmed) {
      setLoading(true);
      const resp = await deleteRepair(repairId);
      if (resp.data) {
        const repairs = job.repairs.filter((r) => r.id !== repairId);
        setJob({ ...job, repairs });
        toast.success("Repair deleted successfully");
      } else {
        toast.error("Error deleting repair " + formatErrorMessage(resp.error));
      }
      setLoading(false);
    }
  }

  function showCreateRepairDialog(event: any): void {
    setShowModal(true);
  }

  async function handleCreateRepair(data: any) {
    console.log("Create Repair", data);

    setLoading(true);
    data.job_id = job.id;
    data.taken_by = user?.name;
    const resp = await addRepair(data);
    if (resp.data) {
      toast.success("Repair created successfully");
      const repairs = [...job.repairs, resp.data];
      setJob({ ...job, repairs });
    } else {
      toast.error("Error creating repair " + formatErrorMessage(resp.error));
    }
    setLoading(false);
  }

  return (
    <div className="container">
      <ProgressSpinner show={loading} />
      <ToastContainer />
      <RepairCreateDialog
        show={showModal}
        job={job}
        onClose={() => setShowModal(false)}
        onCreate={handleCreateRepair}
      />
      <div className="m-1">
        <JobRepairsBreadCrumbs job={job} />
      </div>
      <div className="row p-2">
        <div className="col-md-6">
          <JobCustomerInfo lead={job?.lead} />
        </div>
        <div className="col-md-6">
          <div>
            <span>Job Title: </span>
            <span>{job?.name}</span>
          </div>
          <div className="row">
            <div className="col-auto">Job Number: </div>
            <Link className="text-decoration-none col" to={`/jobs/${job?.id}`}>
              {job?.code ? job?.code : job?.id}
            </Link>
          </div>
        </div>
      </div>

      <div>
        {error && <div>{error}</div>}
        <div>
          {job?.repairs?.map((repair) => (
            <Paper key={repair.id} className="my-4 p-3" elevation={3}>
              <div className="row">
                <div className="col-md-3 mb-1">
                  <label className="mr-1">Repair:</label>
                  <span className="text-strong">{repair.id}</span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-3 mb-1">
                  <label className="">Contact:</label>
                  <div className="text-strong">
                    {repair.contact ?? job?.lead?.contact_name}
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="">Phone:</label>
                  <div className="text-strong">{repair.phone}</div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="">Date Called:</label>
                  <div className="text-strong">
                    {dateFormat(repair.called_at)}
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="">Date to Start:</label>
                  <div className="text-strong">
                    {dateFormat(repair.tostart_at)}
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3 mb-1">
                  <label className="">Taken By:</label>
                  <div className="text-strong">{repair.taken_by}</div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="form-label mr-1">Crew:</label>
                  <div className="text-strong">{repair.crew ?? "None"}</div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="">Date Completed:</label>
                  <div className="text-strong">
                    {dateFormat(repair.completed_at)}
                  </div>
                </div>
                <div className="col-md-3 mb-1">
                  <label className="">Hours Spent:</label>
                  <div className="text-strong">{repair.time_spent}</div>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-6 mb-1">
                  <label className="">Description:</label>
                  <div className="text-strong">{repair.note}</div>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-end mt-1">
                  <div>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => handleViewPDF(repair.id)}
                    >
                      View PDF
                    </button>
                    <button
                      className="btn btn-outline-primary btn-sm ml-1"
                      onClick={() => handleEditRepair(repair.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm ml-1"
                      onClick={() => handleDeleteRepair(repair.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Paper>
          ))}
          {job?.repairs?.length === 0 && (
            <div className="alert alert-info">
              Click on the button below to create a new repair.
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary btn-sm"
              onClick={showCreateRepairDialog}
            >
              Create New Repair
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobRepairList;
