import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchLeads = async (params = {}) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/leads", params);
    console.log("leads fetched ", data.data?.length);
    return data;
  } catch (err: any) {
    console.log("error on fetchLeads " + err);
    throw err;
  }
};

export const fetchLead = async (id: string = "") => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/leads/" + id);
    console.log("lead fetched ");
    return { data };
  } catch (err: any) {
    console.log("error on fetchLead " + err);
    throw err;
  }
};

export const fetchFormFields = async () => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/leads/form-fields");
    console.log("form lists fetched ");
    return { data };
  } catch (err: any) {
    console.log("error on fetchFormLists " + err);
    throw err;
  }
};

export const createLead = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/lead", data);
    console.log("lead saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveLead " + err);
    throw err;
  }
};

export const updateLead = async (input: any) => {
  const { leadId, data } = input;

  const apiService = new ApiService();
  const resp = await apiService.put(apiUrl + "/leads/" + leadId, data);
  return { data: resp };
};

export const deleteLead = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/leads/" + id);
    console.log("lead deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteLead " + err);
    throw err;
  }
};

export const saveLeadLabels = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + "/leads/" + id + "/labels",
      data
    );
    console.log("lead labels saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveLeadLabels " + err);
    throw err;
  }
};

export const importLeads = async (data: any) => {
  const apiService = new ApiService();
  const resp = await apiService.post(apiUrl + "/leads/import", data);
  return resp;
};
