import currency from "currency.js";
import { currencyValue } from "../../common/utils";
import { IDataRow, IGoals } from "./types";

export const generateGoalsData = (data: any, goals: IGoals) => {
  const thisYearSales = getYearSalesData(data, "thisYear");
  const lastYearSales = getYearSalesData(data, "lastYear");
  const salesGoals = getSalesGoalsData(lastYearSales, goals);

  return {
    lastYearSales: lastYearSales,
    thisYearSales: thisYearSales,
    salesGoals: salesGoals,
    salesRepGoals: generateRepGoalsData(salesGoals, goals),
  };
};

export const getYearSalesData = (
  data: IDataRow[],
  yearType: "thisYear" | "lastYear"
) => {
  const year = getYear(yearType);
  const result = data
    .filter((row) => row.year == year)
    .map((row) => row.amount);

  return {
    label: year,
    data: result,
  };
};

const sumArray = (arr: any) => {
  return arr.reduce((total: number, num: number) => {
    return total + Number(num || 0);
  }, 0);
};

const getYear = (yearType: "thisYear" | "lastYear"): string => {
  const today = new Date();
  switch (yearType) {
    case "thisYear":
      return today.getFullYear().toString();
    case "lastYear":
      return (today.getFullYear() - 1).toString();
    default:
      throw new Error(`Invalid yearType: ${yearType}`);
  }
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const defaultPercents = [
  0.014, 0.038, 0.05, 0.09, 0.129, 0.205, 0.19, 0.101, 0.073, 0.06, 0.04, 0.01,
];

export const getMonths = () => months;

const getSalesGoalsData = (lastYear: any, goals: IGoals) => {
  const lastYearPercent = lastYearPercentage(lastYear.data);
  const goalAmount = goals.goalAmount || 1;

  const goalsArr = Array.from({ length: 12 }, (_, i) => {
    return lastYearPercent[i] * goalAmount;
  });

  return {
    label: "Goals",
    data: goalsArr,
  };
};

const lastYearPercentage = (data: any[]) => {
  const yearTotal = sumArray(data);
  if (data.length < 12) {
    return defaultPercents;
  }

  const result = Array.from({ length: 12 }, (_, i) => data[i] / yearTotal);
  return result;
};

const generateRepGoalsData = (salesGoals: any, goals: IGoals): number[][] => {
  const monthlyGoals = salesGoals.data;
  const numReps = Number(goals.numberReps) || 1;
  const avgJobAmount = goals.avgJobAmount || 1;
  const percent = (goals.percentJobsSold || 1) / 100;

  const table = Array.from({ length: 12 }, (_, i) => {
    const goalsMonthAmount = currencyValue(monthlyGoals[i]);
    const jobsMonth = Math.ceil(goalsMonthAmount / numReps / avgJobAmount);
    const leadsMonth = jobsMonth / percent;

    const leadsDay = leadsMonth / 20;
    const leadsWeek = Math.round(leadsMonth / 4);
    const leadsMonthRounded = Math.round(leadsMonth);
    const jobsWeek = Math.round(jobsMonth / 4);
    const jobAmountPerRep = goalsMonthAmount / numReps;

    return [
      leadsDay,
      leadsWeek,
      leadsMonthRounded,
      jobsWeek,
      jobsMonth,
      jobAmountPerRep,
    ];
  });

  return table;
};

export const getAmountUnderGoal = (jobsSold: any[], goals: IGoals) => {
  const lastYearSales = getYearSalesData(jobsSold, "lastYear");
  const thisYearSales = getYearSalesData(jobsSold, "thisYear");
  const salesGoals = getSalesGoalsData(lastYearSales, goals);
  const thisYearTotal = sumArray(thisYearSales.data);
  const monthIndex = new Date().getMonth() + 1;
  const salesGoalsUntilNow = salesGoals.data.slice(0, monthIndex);
  const goalAmount = sumArray(salesGoalsUntilNow);

  return goalAmount - thisYearTotal;
};
