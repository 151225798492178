import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSave, AiTwotoneSwitcher } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { formatErrorMessage } from "../../../common/utils";
import {
  fetchProposalTerms,
  updateProposalTerms,
} from "../../../services/accountApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { editorSettings } from "../../job/config";
import { useNavigate } from "react-router-dom";
import ProgressSpinner from "../../common/ProgressSpinner";

interface Props {
  name?: string;
}

const ProposalTerms: React.FC<Props> = () => {
  const [terms, setTerms] = useState<any>({});
  let rteRef = useRef<any>(null);
  const navigate = useNavigate();

  const { data, isError, error, isLoading } = useQuery({
    queryKey: ["proposal-terms"],
    queryFn: () => fetchProposalTerms(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setTerms(data?.data || []);
    if (isError) {
      const msg = formatErrorMessage(error.message);
      console.log("Error fetching terms: ", msg);
      toast.error(msg);
    }
  }, [data, error]);

  function handleEditorChange(event: any): void {
    const value = event.value || "";
    console.log("handleEditorChange", value);
  }

  async function saveTerms(): Promise<void> {
    console.log("saveTerms");
    console.log("text", rteRef.current.value);
    const data = {
      text: rteRef.current.value,
    };

    try {
      const resp = await updateProposalTerms(data);
      setTerms(resp.data);
      toast.success("Text saved successfully");
    } catch (err: any) {
      console.log("error on saveTerms" + err);
      toast.error("Error saving terms text: " + err.message);
    }
  }

  return (
    <div className="container">
      <ToastContainer />
      <ProgressSpinner show={isLoading} />
      <BreadcrumbsComponent
        links={[{ to: "/settings", label: "Settings" }]}
        leaf="Proposal Terms"
      />
      <div className="row mb-3">
        <div className="col">
          <div className="page-title">Proposal Terms</div>
        </div>
        <div className="col-auto">
          <button className="btn btn-default" onClick={saveTerms}>
            <AiOutlineSave className="mr-2" />
            Save
          </button>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-secondary"
            onClick={() => navigate("/settings/proposal-disclaimer")}
          >
            <AiTwotoneSwitcher className="mr-2" />
            Proposal Disclaimer
          </button>
        </div>
      </div>
      {
        <div className="row mb-4 d-flex justify-content-center">
          <div className="" style={{ width: 910 }}>
            <RichTextEditorComponent
              toolbarSettings={editorSettings}
              value={terms?.text}
              height={892}
              ref={(editor) => (rteRef.current = editor)}
              change={handleEditorChange}
            >
              <Inject services={[Toolbar, HtmlEditor]} />
            </RichTextEditorComponent>
          </div>
        </div>
      }
    </div>
  );
};

export default ProposalTerms;
