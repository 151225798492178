import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchUser = async (id = "me") => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/users/" + id);
    console.log("info fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchInfo " + err);
    throw err;
  }
};

export const fetchUsers = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/users");
    console.log("users fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchUsers " + err);
    throw err;
  }
};

export const updateUser = async (user: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/users/" + user.id, user);
    console.log("user updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateUser " + err);
    return { error: err.message };
  }
};

export const createUser = async (user: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/users", user);
    console.log("user created ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on createUser " + err);
    return { error: err.message };
  }
};

export const deleteUser = async (userId: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/users/" + userId);
    console.log("user deleted ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on deleteUser " + err);
    return { error: err.message };
  }
};

export const updateUserStatus = async (userId: any, active: boolean) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/users/" + userId + "/status", {
      active,
    });
    console.log("user status updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateUserStatus " + err);
    return { error: err.message };
  }
};

export const fetchRoles = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/roles");
    console.log("roles fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchRoles " + err);
    return { error: err.message };
  }
};

export const changeUserPassword = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/account/password", data);
    console.log("user password changed ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on changeUserPassword " + err);
    return { error: err.message };
  }
};
