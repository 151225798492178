import { formatErrorMessage } from "../../../common/utils";
import { deleteJobLabel } from "../../../services/labelsApiService";
import { ILabel } from "../../lead/types";

interface IProps {
  jobId?: string;
  labels: ILabel[];
  onDeleted: (id: any) => void;
}

const JobPills: React.FC<IProps> = (props) => {
  const { jobId, labels } = props;

  async function handleDeleteLabel(id: any) {
    console.log("deleteLabel " + id);
    const resp = await deleteJobLabel(id);
    if (resp.data) {
      props.onDeleted(id);
    } else {
      console.log("error on deleteLabel " + formatErrorMessage(resp.error));
    }
  }

  return (
    <div className="d-flex flex-row-reverse">
      {labels?.map((label: ILabel) => (
        <button
          key={label.pivot.id}
          type="button"
          className="btn btn-info btn-sm mr-2"
          onClick={() => handleDeleteLabel(label.pivot.id)}
        >
          {label.name}
          <span className="bi bi-x-circle ml-2"></span>
        </button>
      ))}
    </div>
  );
};

export default JobPills;
