import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IGoals } from "./types";
import { showToaster } from "../../services/toastService";
import { formatErrorMessage } from "../../common/utils";
import { updateGoals } from "../../services/reportApiService";
import { useMutation } from "@tanstack/react-query";

interface IProps {
  goals: IGoals;
  show: boolean;
  onHide: () => void;
  onSaved: (goals: any) => void;
}

const validationRules = {
  required: "field is required",
  pattern: {
    value: /^[0-9\.]*$/,
    message: "field must be a number",
  },
};

const GoalsEditDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(props.goals);
    setShowModal(props.show);
  }, [props.show]);

  const { mutateAsync } = useMutation({
    mutationFn: updateGoals,
  });

  function handleCloseDialog(): void {
    setShowModal(false);
    props.onHide();
  }

  const onSubmit = (data: any) => {
    console.log("onSubmit", data);
  };

  async function handleSaveGoals() {
    await handleSubmit(onSubmit)();
    if (Object.keys(errors).length || !isDirty) return;
    const data = getValues();

    handleCloseDialog();

    try {
      const resp = await mutateAsync(data);
      props.onSaved(resp.data);
      showToaster("Goals saved: ");
    } catch (error: any) {
      showToaster(
        "Error saving goals:" + formatErrorMessage(error.message),
        "error"
      );
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
      variant="wider"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Goals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="goalAmount"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Amount
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("goalAmount", validationRules)}
              />
              {errors.goalAmount && (
                <span className="text-danger">
                  {errors.goalAmount.message.toString()}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="avgJobAmount"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Avg Job Amount
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("avgJobAmount", validationRules)}
              />
              {errors.avgJobAmount && (
                <span className="text-danger">
                  {errors.avgJobAmount.message.toString()}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="percentJobsSold"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Percentage of Jobs Sold
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("percentJobsSold", validationRules)}
              />
              {errors.percentJobsSold && (
                <span className="text-danger">
                  {errors.percentJobsSold.message.toString()}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="numberReps"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Number of Reps
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("numberReps", validationRules)}
              />
              {errors.numberReps && (
                <span className="text-danger">
                  {errors.numberReps.message.toString()}
                </span>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSaveGoals} disabled={!isDirty}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GoalsEditDialog;
