import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchChecklist = async (id: string = "") => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/checklist" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    return resp;
  } catch (err: any) {
    console.log("error on fetchChecklist " + err);
    throw err;
  }
};

export const upsertChecklistItem = async (data: any) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/checklist";
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on upsertChecklistItem " + err);
    throw err;
  }
};

export const updateChecklistBulk = async (data: any) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/checklist/bulk";
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on updateChecklistBulk " + err);
    return { error: err.message };
  }
};

export const deleteChecklistItem = async (id: string) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/checklist/" + id;
    const resp = await apiService.delete(url);
    return resp;
  } catch (err: any) {
    console.log("error on deleteChecklistItem " + err);
    return { error: err.message };
  }
};
