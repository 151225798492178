import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import LinkRouter from "../../common/LinkRouter";
import { IJob } from "../types";

interface IProps {
  job: IJob;
}

const PaverOrdersBreadCrumbs: React.FC<IProps> = ({ job }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="inherit" to={`/jobs/${job?.id}`}>
        Job
      </LinkRouter>
      <Typography color="text.primary">Paver Orders</Typography>
    </Breadcrumbs>
  );
};

export default PaverOrdersBreadCrumbs;
