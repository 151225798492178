import React from "react";

interface Props {
  register: any;
  onAddEmail: (e: any) => void;
  email: string;
}

const EmailInput: React.FC<Props> = (props) => {
  const { register, onAddEmail, email } = props;

  const sendEmail = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="name@mail.com"
          {...register("email")}
        />
        <div className="btn-group">
          <span className="input-group-text" id="basic-addon2">
            main
          </span>
          <button type="button" className="btn btn-default" onClick={sendEmail}>
            <i className="bi bi-envelope-fill"></i>
          </button>
          <button
            type="button"
            className="btn btn-default dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="#" onClick={onAddEmail}>
                <i className="bi bi-plus-square mr-2"></i> Add Email
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmailInput;
