import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchPhones = async (id: string) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/phones" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    console.log("phones fetched ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on fetchPhones " + err);
    return { error: err.message };
  }
};

export const fetchPhonesByLead = async (leadId: string) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/leads/" + leadId + "/phones";
    const resp = await apiService.get(url);
    console.log("phones fetched ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on fetchPhonesByLead " + err);
    return { error: err.message };
  }
};

export const createPhone = async (leadId: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(
      `${apiUrl}/leads/${leadId}/phones`,
      data
    );
    console.log("phone created ", data.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on createPhone " + err);
    return { error: err.message };
  }
};

export const updatePhone = async (id: string, data = {}) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/phones/" + id, data);
    console.log("phone updated", data);
    return resp;
  } catch (err: any) {
    console.log("error on updatePhone " + err);
    return { error: err.message };
  }
};

export const deletePhone = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/phones/" + id);
    console.log("phone deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deletePhone " + err);
    return { error: err };
  }
};
