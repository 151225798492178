import { useEffect, useState } from "react";
import { generateGoalsData, months } from "./goalsService";
import { IGoals } from "./types";
import { numberFormat } from "./../../common/utils";

interface IProps {
  jobsSold: any;
  goals: IGoals;
}

const GoalsTable = (props: IProps) => {
  const { goals, jobsSold } = props;
  const [repGoals, setRepGoals] = useState<any>([]);
  const [lastYearSales, setLastYearSales] = useState<any>({} as any);
  const [thisYearSales, setThisYearSales] = useState<any>({} as any);
  const [salesGoals, setSalesGoals] = useState<any>([]);

  useEffect(() => {
    const { lastYearSales, thisYearSales, salesGoals, salesRepGoals } =
      generateGoalsData(jobsSold, goals);

    setLastYearSales(lastYearSales);
    setThisYearSales(thisYearSales);
    setSalesGoals(salesGoals);
    setRepGoals(salesRepGoals);
  }, [jobsSold, goals]);

  return (
    <div className="row px-4">
      <div className="row mb-3">
        <table
          className="table table-striped table-sm"
          style={{ fontSize: 11 }}
        >
          <thead>
            <tr>
              <th>Sales</th>
              {months.map((month) => (
                <th key={month}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{lastYearSales?.label}</th>
              {lastYearSales?.data?.map((goal: any, index: number) => (
                <td key={index}>{numberFormat(goal, 0)}</td>
              ))}
            </tr>
            <tr>
              <th>{thisYearSales?.label}</th>
              {thisYearSales?.data?.map((goal: any, index: number) => (
                <td key={index}>{numberFormat(goal, 0)}</td>
              ))}
            </tr>
            <tr>
              <th>Goals</th>
              {salesGoals?.data?.map((goal: any, index: number) => (
                <td key={index}>{numberFormat(goal, 0)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Rep Goals</th>
              <th>Daily Leads</th>
              <th>Weekly Leads</th>
              <th>Monthly Leads</th>
              <th>Jobs Sold/Week</th>
              <th>Jobs Sold/Month</th>
              <th>Amount Sold/Month</th>
            </tr>
          </thead>
          <tbody>
            {repGoals.map((item: any, index: number) => (
              <tr key={index}>
                <td> {months[index]}</td>
                <td>{numberFormat(item[0], 1)}</td>
                <td>{item[1]}</td>
                <td>{item[2]}</td>
                <td>{item[3]}</td>
                <td>{item[4]}</td>
                <td className="px-3 text-end">{numberFormat(item[5], 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoalsTable;
