import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { formatDateTime } from "../../../common/dateUtils";
import { createNote, deleteNote } from "../../../services/noteApiService";
import { INote } from "../types";

interface Props {
  notes: any[];
  leadId: string;
}

const LeadNotes: React.FC<Props> = (props) => {
  const inNotes = props.notes;
  const [notes, setNotes] = useState<INote[]>([]);
  const [filteredNotes, setFilteredNotes] = useState<any[]>([]);
  const [noteTags, setNoteTags] = useState<any[]>([]);
  const [newNote, setNewNote] = useState<string>("");
  const [removedNoteId, setRemovedNoteId] = useState<string | null>(null);

  useEffect(() => {
    setNotes(inNotes);
  }, [inNotes]);

  useEffect(() => {
    if (!notes) return;
    createNoteTags(notes);
    filterNotes({ target: { value: "all" } });
  }, [notes]);

  function filterNotes(event: any): void {
    let filtered = [];
    let selectedTag = event?.target?.value;
    if (selectedTag === "deleted") {
      filtered = notes.filter((note: any) => note.deleted_at != null);
    } else if (selectedTag !== "all") {
      filtered = notes?.filter(
        (note: any) =>
          note.note.includes(selectedTag) && note.deleted_at == null
      );
    } else {
      filtered = notes?.filter((note: any) => note.deleted_at == null);
    }
    setFilteredNotes(filtered);
  }

  const createNoteTags = (notes: any[]) => {
    const list = notes?.map((note: any) => {
      if (note.deleted_at) {
        return "deleted";
      }
      const matched = note.note.match(/^#\w+/);
      if (matched) {
        return matched[0];
      }
    });
    const filteredList = list?.filter((value, index, self) => {
      return value && self.indexOf(value) === index;
    });
    setNoteTags(filteredList);
  };

  async function handleDeleteNote(event: any, id: any): Promise<void> {
    event.preventDefault();
    if (id) {
      const resp = await deleteNote(id);

      if (resp.error) {
        const message = resp.error.message;
        console.log("error deleting note: ", message);
        //createToastNotification(message);
      } else {
        console.log("note deleted" + resp);
        setRemovedNoteId(id);
        setTimeout(() => {
          setRemovedNoteId(null);
        }, 300);
        setNotes((prevNotes) => prevNotes.filter((note) => note.id !== id));
        //createToastNotification(message);
      }
    }
  }

  function handleOnChange(event: any): void {
    setNewNote(event.target.value);
  }

  async function addNote(): Promise<void> {
    if (newNote?.length > 1) {
      const data = {
        note: newNote,
        lead_id: props.leadId,
        user_id: "",
      };

      console.log("addNote: ", data);

      const resp = await createNote(data);

      console.log("resp: ", resp);

      if (resp.error) {
        const message = resp.error.message;
        console.log("error adding note: ", message);
        //createToastNotification(message);
      } else {
        console.log("note added");
        //createToastNotification(message);
        setNotes([resp.data, ...notes]);
        setNewNote("");
      }
    }
  }

  function handleEnterKeyPressed(event: any): void {
    if (event.key === "Enter") {
      addNote();
    }
  }

  return (
    <div className="col-md-6">
      {/* @can('edit') */}
      <div className="form-group note-form">
        <label className="form-label">Notes</label>
        <div style={{ display: "inline-block", margin: "2px 20px" }}>
          <select className="form-control" onChange={filterNotes}>
            <option value="all">All</option>
            {noteTags?.map((tag: any) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>
        <div className="note-list">
          {filteredNotes.map((note: any) => (
            <div
              key={note.id}
              className={`note-card ${
                note.id === removedNoteId ? "note-card-removed" : ""
              }`}
            >
              <a
                onClick={(e) => handleDeleteNote(e, note.id)}
                href="#"
                title="delete note"
              >
                <BsTrash />
              </a>
              <div className=" card-text pb-2 pt-3">{note.note}</div>
              <span className="note-card-info">
                Created on: {formatDateTime(note.created_at)}
              </span>
              {note.deleted_at && (
                <span className="note-card-danger">
                  Deleted on: {formatDateTime(note.deleted_at)}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12 col-lg-12">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="new note"
              name="newNote"
              value={newNote}
              onChange={handleOnChange}
              onKeyDown={handleEnterKeyPressed}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => addNote()}
              disabled={!newNote}
            >
              <i className="bi bi-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadNotes;
