import { Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { RiDraggable } from "react-icons/ri";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { formatErrorMessage } from "../../../common/utils";
import { deleteLabel } from "../../../services/labelsApiService";
import { showToaster } from "../../../services/toastService";
import LabelsCreateDialog from "./LabelsCreateDialog";

interface IProps {
  labels: any;
  type: string;
  onListUpdated: (labels: any[], type: string) => void;
}

const LabelsList: React.FC<IProps> = (props) => {
  const [search, setSearch] = useState("");
  const [labels, setLabels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<any>({});

  const { mutateAsync } = useMutation({
    mutationFn: deleteLabel,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    const inputs = props.labels || [];
    setLabels(inputs);
  }, [props.labels]);

  function showEditDialog(id: any): void {
    const label = props.labels.find((item: any) => item.id === id);
    setSelectedLabel(label);
    setShowModal(true);
  }

  async function showDeleteDialog(id: number): Promise<void> {
    if (id) {
      const result = await Swal.fire({
        text: "Are you sure you want to delete this label?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (result.isConfirmed) {
        try {
          const resp = await mutateAsync(id);
          // handleOnDeleted(id);
          queryClient.invalidateQueries({ queryKey: ["form-fields"] });
          showToaster("Label deleted successfully.");
        } catch (err: any) {
          showToaster(
            "Label to delete template: " + formatErrorMessage(err.message),
            "error"
          );
        }
      }
    }
  }

  function handleSearchChanged(event: any): void {
    setSearch(event.target.value);
    const searchValue = event.target.value.toLowerCase();
    const filteredList = props.labels?.filter((item: any) => {
      return item.name.toLowerCase().includes(searchValue);
    });
    setLabels(filteredList);
  }

  function handleOnSaved(label: any): void {
    const newLabels = labels.map((item: any) => {
      if (item.id === label.id) {
        return label;
      }
      return item;
    });
    props.onListUpdated(newLabels, props.type);
  }

  function handleOnDeleted(id: number): void {
    const newLabels = labels.filter((item: any) => item.id !== id);
    props.onListUpdated(newLabels, props.type);
  }

  return (
    <>
      <LabelsCreateDialog
        label={selectedLabel}
        type={props.type}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="p-3">
        <div className="row my-3 ml-3" style={{ maxWidth: 400 }}>
          <TextField
            id="filled-search"
            label="Search"
            type="search"
            variant="outlined"
            onChange={handleSearchChanged}
            value={search}
          />
        </div>
        <div className="row mt-3">
          {labels.map((item: any) => (
            <div>
              <Paper
                elevation={3}
                key={item.id}
                className="p-2 my-1 ml-3 sort-item"
                style={{ width: 600 }}
              >
                <div className="d-flex">
                  <div>
                    <RiDraggable />
                  </div>
                  <div className="flex-grow-1 fw-bold mt-1 ml-3">
                    <div className={item.active ? "" : "text-black-50"}>
                      {item.name}
                    </div>
                  </div>
                  <div className="sort-item-actions">
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mr-2"
                        title="edit template"
                        onClick={() => showEditDialog(item.id)}
                      >
                        <BsPencilFill />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        title="delete template"
                        onClick={() => showDeleteDialog(item.id)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LabelsList;
