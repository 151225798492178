import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, login, saveAuthToken } from "../../auth/auth";
import classNames from "classnames";
import { apiUrl } from "../../config/app";

type Inputs = {
  email: string;
  password: string;
};

const Login2: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Inputs>();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const forgotPasswordUrl = `${apiUrl}/forgot-password`.replace("/api", "");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
    if (isAuthenticated()) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  const onSubmit: SubmitHandler<Inputs> = async (data: any) => {
    setError("");
    const result = await login(data);
    if (result.error) {
      setError(result.error);
      return;
    }
    if (result.data.access_token) {
      saveAuthToken(result.data);
      setIsLoggedIn(true);
    }
  };

  const submitButtonClass = classNames("submit-button", {
    disabled: !isDirty || errors.email || errors.password,
  });

  return (
    <div className="w3-login-container">
      <div className="w3-login-page">
        <div className="w3-login-form">
          <div className="w3-login-form-header mt-2">
            <h3>LOGIN</h3>
            <p>Please enter your credentials to login.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              id="username"
              type="text"
              {...register("email")}
              required
              placeholder="username"
            />
            <input
              id="password"
              type="password"
              {...register("password")}
              placeholder="password"
              required
            />
            <div className="text-danger">{error}</div>
            <button className="mt-3">login</button>
            <p className="w3-login-form-message mt-3">
              <a href={forgotPasswordUrl}>Forgot Password?</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login2;
