import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSave, AiTwotoneSwitcher } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { formatErrorMessage } from "../../../common/utils";
import {
  fetchProposalDisclaimer,
  updateProposalDisclaimer,
} from "../../../services/accountApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { editorSettings } from "../../job/config";
import { useNavigate } from "react-router-dom";
import ProgressSpinner from "../../common/ProgressSpinner";

interface Props {
  name?: string;
}

const ProposalDisclaimer: React.FC<Props> = () => {
  const [disclaimer, setDisclaimer] = useState<any>({});
  let rteRef = useRef<any>(null);
  const navigate = useNavigate();

  const { data, isError, error, isLoading } = useQuery({
    queryKey: ["proposal-disclaimer"],
    queryFn: () => fetchProposalDisclaimer(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setDisclaimer(data?.data || []);
    if (isError) {
      const msg = formatErrorMessage(error.message);
      console.log("Error fetching disclaimer: ", msg);
      toast.error(msg);
    }
  }, [data, error]);

  // // custom toolbar item. Save button
  // // problem: the save button is not work well
  // // it saves the outdated text sometimes
  // function handleEditSaveClick(): any {
  //   saveDisclaimer();
  // }

  // const saveButton: any = {
  //   template:
  //     '<button class="e-tbar-btn e-btn mx-2"><div class="mx-2"><i class="bi bi-floppy mr-2"></i>Save</div></button>',
  //   click: () => handleEditSaveClick(),
  // };

  // const editorSettings2 = {
  //   items: [...editorSettings.items, saveButton],
  // };

  function handleEditorChange(event: any): void {
    const value = event.value || "";
    console.log("handleEditorChange");
  }

  async function saveDisclaimer(): Promise<void> {
    console.log("saveDisclaimer");
    const data = {
      text: rteRef.current.value,
    };

    try {
      const resp = await updateProposalDisclaimer(data);
      setDisclaimer(resp.data);
      toast.success("Text saved successfully");
    } catch (err: any) {
      console.log("error on saveDisclaimer" + err);
      toast.error("Error saving disclaimer text: " + err.message);
    }
  }

  return (
    <div className="container">
      <ToastContainer />
      <ProgressSpinner show={isLoading} />
      <BreadcrumbsComponent
        links={[{ to: "/settings", label: "Settings" }]}
        leaf="Proposal Disclaimer"
      />
      <div className="row mb-3">
        <div className="col">
          <div className="page-title">Proposal Disclaimer</div>
        </div>
        <div className="col-auto">
          <button className="btn btn-default" onClick={saveDisclaimer}>
            <AiOutlineSave className="mr-2" />
            Save
          </button>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-secondary"
            onClick={() => navigate("/settings/proposal-terms")}
          >
            <AiTwotoneSwitcher className="mr-2" />
            Proposal Terms
          </button>
        </div>
      </div>
      {
        <div className="row mb-4 d-flex justify-content-center">
          <div className="" style={{ width: 910 }}>
            <RichTextEditorComponent
              toolbarSettings={editorSettings}
              value={disclaimer?.text}
              height={892}
              ref={(editor) => (rteRef.current = editor)}
              change={handleEditorChange}
            >
              <Inject services={[Toolbar, HtmlEditor]} />
            </RichTextEditorComponent>
          </div>
        </div>
      }
    </div>
  );
};

export default ProposalDisclaimer;
