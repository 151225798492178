import { AddBox } from "@mui/icons-material";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { fetchChecklist } from "../../../services/checklistApiServices";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import ChecklistsCreateDialog from "./ChecklistsCreateDialog";
import ChecklistsList from "./ChecklistsList";
import { useQuery } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";

const ChecklistsEdit: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const [options, setOptions] = React.useState<string[]>([]);

  const { data, isError, error } = useQuery({
    queryKey: ["checklist"],
    queryFn: () => fetchChecklist(),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isError) {
      toast.error(
        "Error fetching checklist  " + formatErrorMessage(error.message)
      );
    }
    const sortedData = sortData(data?.data || []);
    setItems(sortedData);
    setSetOptions(sortedData);
  }, [data, error]);

  function handleCreate(event: any): void {
    setShowModal(true);
  }

  function handleOnSaved(item: any): void {
    const newItems = [...items, item];
    setItems(newItems);
  }

  function handleOnListUpdated(items: any[]): void {
    setItems(items);
  }

  function sortData(data: any): any {
    return data?.sort((a: any, b: any) => {
      return Number(a.display_order) - Number(b.display_order);
    });
  }

  function setSetOptions(items: any): void {
    const types: string[] = items
      .map((item: any) => item.type)
      .filter((d: string) => !!d);

    setOptions(Array.from(new Set(types)));
  }

  return (
    <>
      <ToastContainer />
      <ChecklistsCreateDialog
        item={null}
        options={options}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="container">
        <BreadcrumbsComponent
          links={[{ to: "/settings", label: "Settings" }]}
          leaf="Checklist"
        />
        <div className="row px-3 mt-3">
          <div className="col page-title">Checklist</div>
          <div className="col d-flex justify-content-end">
            <button className="btn btn-primary mr-5" onClick={handleCreate}>
              <AddBox className="mr-2" />
              {"Create New Item"}
            </button>
          </div>
        </div>

        <div className="row">
          <ChecklistsList items={items} onListUpdated={handleOnListUpdated} />
        </div>
      </div>
    </>
  );
};

export default ChecklistsEdit;
