import React, { useEffect, useState } from "react";
import { arraysAreSimilar } from "../../../common/utils";
import { saveJobLabels } from "../../../services/jobApiService";
import { usePanelsContext } from "../../../context/PanelsContext";

interface Props {
  jobId: string;
  labels: any[];
  jobLabels: any[];
  updateJobLabels: (labels: any[]) => void;
  setLoading?: (loading: boolean) => void;
}

const JobLabels: React.FC<Props> = (props) => {
  const { jobId, labels } = props;
  const [jobLabels, setJobLabels] = useState<any[]>([]);
  const jobLabelIds = jobLabels?.map((label: any) => label.id);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const { setPanelDisplay } = usePanelsContext();

  useEffect(() => {
    setJobLabels(props.jobLabels);
  }, []);

  useEffect(() => {
    if (!jobLabels) return;
    checkItems(jobLabels);
  }, [jobLabels]);

  function handleClosePane() {
    setPanelDisplay("progress", false);
  }

  async function updateLabels(): Promise<void> {
    const checkedItemIds = getCheckedItemIds();
    console.log("applyLabels: ", checkedItemIds);

    if (!arraysAreSimilar(checkedItemIds, jobLabelIds)) {
      props.setLoading(true);
      console.log("updateJobLabels: ", checkedItemIds);
      const data = {
        job_id: jobId,
        label_ids: checkedItemIds,
      };
      const resp = await saveJobLabels(jobId, data);

      console.log("resp: ", resp);

      if (resp.error) {
        const message = resp.error.message;
        console.log("error updating labels: ", message);
        //createToastNotification(message);
      } else {
        console.log("labels updated successfully");
        //createToastNotification(message);
        //setJobLabels(resp.data.labels);
        props.updateJobLabels(resp.data.labels);
        handleClosePane();
      }
      props.setLoading(false);
    }
  }

  const handleChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  function getCheckedItemIds(): any[] {
    const checkedKeys = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    return checkedKeys;
  }

  function checkItems(jobLabels: any[]) {
    const checkedItems: any = {};
    for (const label of jobLabels) {
      checkedItems[label.id] = true;
    }
    setCheckedItems(checkedItems);
  }
  return (
    <div>
      <ul className="list-group">
        {labels.map((label: any) => (
          <li key={label.id} className="list-group-item">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  className="mr-3"
                  name={label.id}
                  checked={checkedItems[label.id]}
                  onChange={handleChange}
                />
                {label.name}
              </label>
            </div>
          </li>
        ))}
        <li key="divider" className="dropdown-divider"></li>
        <li key="apply" className="list-group-item">
          <div className="col-md-12 col-lg-12 text-center">
            <a
              className="btn btn-primary btn-sm"
              onClick={updateLabels}
              href="#"
            >
              Save
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default JobLabels;
