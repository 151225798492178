import React, { useState } from "react";
import LeadDataGrid from "./LeadDataGrid";
import { BsPencilSquare } from "react-icons/bs";
import CreateLeadDialog from "./components/CreateLeadDialog";

interface Props {
  name?: string;
}

const LeadList: React.FC<Props> = () => {
  const [showDialog, setShowDialog] = useState(false);

  function buttonClick(event: any): void {
    setShowDialog(true);
  }

  function handleOnHide(): void {
    setShowDialog(false);
  }

  return (
    <div className="p-3">
      <div className="row mb-3">
        <div className="col">
          <div className="page-title">Leads</div>
        </div>
        <div className="col-auto">
          <button className="btn btn-default" onClick={buttonClick}>
            <BsPencilSquare className="mr-2" />
            Add Lead
          </button>
        </div>
      </div>
      <CreateLeadDialog show={showDialog} onHide={handleOnHide} />
      <LeadDataGrid />
    </div>
  );
};

export default LeadList;
