import Pagination from "@mui/material/Pagination";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  Inject,
  Resize,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatErrorMessage,
  parseQueryStringToJSON,
} from "../../../common/utils";
import { fetchInstallerPayments } from "../../../services/installerPaymentApiService";
import ProgressSpinner from "../../common/ProgressSpinner";
import { IMeta, IPageQuery } from "../../common/types";

interface IRowData {
  id: number;
  [key: string]: any;
}

const InstallerPaymentsList: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({} as IPageQuery);
  const [pageLoaded, setPageLoaded] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const queryString = parseQueryStringToJSON();
    let _params = { ...params, ...queryString };
    if (location.state) {
      _params = location.state;
      setSearch(_params.query || "");
    }
    setParams(_params);
    setPageLoaded(true);
  }, []);

  const { data: paymentsData, isLoading } = useQuery({
    queryKey: ["installer-payments", { params }],
    queryFn: () => fetchInstallerPayments(params),
    enabled: pageLoaded,
  });

  let grid: Grid | null;

  const rowSelected = () => {
    if (grid) {
      const selected: any[] = grid.getSelectedRecords();
      if (!selected || selected.length === 0) {
        return;
      }
      const paymentId = selected?.[0]?.id;
      const options = { state: params };
      navigate(`/installer-payments/${paymentId}`, options);
    }
  };

  useEffect(() => {
    if (paymentsData?.error) {
      setError(formatErrorMessage(paymentsData.error));
    }
  }, [paymentsData]);

  const pagination: IMeta = paymentsData?.meta || ({} as IMeta);

  function handleOnChange(event: any): void {
    setSearch(event.target.value);
  }

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  function handleSearchClick() {
    handleSearch();
  }

  async function handleSearch(extra: any = {}) {
    console.log("search clicked");
    if (!search && !extra.status) {
      return;
    }
    const queryString = parseQueryStringToJSON();

    const params = {
      query: search,
      ...queryString,
      ...extra,
    };
    setParams(params);
  }

  async function handlePaginationChange(event: any, page: number) {
    if (page == pagination.current_page) {
      return;
    }

    const queryString = parseQueryStringToJSON();
    const params: IPageQuery = {
      page: page,
      query: pagination.query,
      sort_by: pagination.sort_by,
      sort_direction: pagination.sort_direction,
      size: pagination.per_page,
      ...queryString,
    };
    setParams(params);
  }

  function handleFilterChange(e: any): void {
    const status = e.target.value;
    handleSearch({ status });
  }

  function handleClearClick(): void {
    setSearch("");
    const queryString = parseQueryStringToJSON();
    const params: any = {
      ...queryString,
      query: "",
    };
    setParams(params);
  }

  if (error) {
    return (
      <div className="row m-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="p-3">
        <div className="page-title">Installer Payments</div>
        <ProgressSpinner show={isLoading} />
        <div className="row mb-3 mt-3">
          <div className="col-md-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={search}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleSearchClick}
                >
                  Search
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleClearClick}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6">
            <Pagination
              count={pagination.last_page}
              page={pagination.current_page || 1}
              onChange={handlePaginationChange}
              shape="rounded"
            />
          </div>
          <div className="col d-flex justify-content-end mt-2 text-secondary">
            payments found: <span className="ml-1"> {pagination.total}</span>
          </div>
        </div>
        <div className="row mb-3" style={{ minWidth: 1000 }}>
          {error && <div className="text-danger">{error}</div>}
          <GridComponent
            dataSource={paymentsData?.data}
            allowSorting={true}
            allowResizing={true}
            rowSelected={rowSelected}
            onClick={rowSelected}
            ref={(g) => (grid = g)}
          >
            <Inject services={[Resize, Sort]} />
            <ColumnsDirective>
              <ColumnDirective field="job.code" headerText="Job #" width={92} />
              <ColumnDirective field="job.name" headerText="Job" />
              <ColumnDirective
                field="job.lead.customer_name"
                headerText="Customer"
              />
              <ColumnDirective field="installer" headerText="Installer" />
              <ColumnDirective
                field="created_at"
                headerText="Date"
                format="MMM dd, yyyy"
                type="date"
              />
            </ColumnsDirective>
            <Inject services={[Sort]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default InstallerPaymentsList;
