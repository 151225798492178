import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { usePanelsContext } from "../../../context/PanelsContext";
import { extractLineItems } from "../JobUtils";
import { IProposalTemplate } from "../types";

interface IProps {
  proposalNotes: string;
  addJobLineItems: (lines: IProposalTemplate[]) => void;
}

const ProposalNotesPane: React.FC<IProps> = (props) => {
  const [lines, setLines] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const { panels, setPanelDisplay } = usePanelsContext();
  const isPaneOpen = !!panels.notes;

  useEffect(() => {
    if (isPaneOpen) {
      const lines = extractLineItems(props.proposalNotes);
      const checkedItems = getCheckedItems(lines);
      setLines(lines);
      setCheckedItems(checkedItems);
    }
  }, [isPaneOpen]);

  function getCheckedItems(_lines: any[]): any {
    let checkedItems: any = {};
    for (const _line of _lines) {
      checkedItems[_line.id] = 1;
    }
    return checkedItems;
  }

  function handleChecklistChange(event: any) {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  }

  function handleAddLineClick() {
    const selected = lines.filter(
      (temp: IProposalTemplate) => checkedItems[temp.id]
    );
    props.addJobLineItems(selected);
    console.log("Adding lines", selected);
    handleClosePane();
  }

  function handleClosePane(): void {
    setPanelDisplay("notes", false);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={isPaneOpen}
        title="Line Templates"
        width="40%"
        onRequestClose={handleClosePane}
      >
        <div className="container-fluid">
          <div className="list-group">
            {lines?.map((line: any) => (
              <div key={line.id} className="card my-2">
                <div className="card-body">
                  <input
                    type="checkbox"
                    className="mr-3"
                    name={line.id}
                    checked={checkedItems[line.id]}
                    onChange={handleChecklistChange}
                  />
                  <div className="card-text ">{line.description}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary" onClick={handleAddLineClick}>
              Add Lines
            </button>
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

export default ProposalNotesPane;
