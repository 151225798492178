import React, { useEffect, useState } from "react";
import { IRepairImage } from "./types";
import { PiImagesSquareDuotone } from "react-icons/pi";
import Swal from "sweetalert2";

interface IProps {
  images: IRepairImage[];
  deleteImage: (id: any) => void;
  previewImage: (id: any) => void;
}

const RepairImageList: React.FC<IProps> = (props) => {
  const [repairImages, setRepairImages] = useState<IRepairImage[]>([]);

  useEffect(() => {
    setRepairImages(props.images || []);
  }, [props.images]);

  function deleteImage(event: any, id: any): void {
    event.preventDefault();
    if (!id) return;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this image!",
      //   icon: "warning",
      position: "top",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteImage(id);
      }
    });
  }

  function previewImage(event: any, id: any): void {
    event.preventDefault();
    if (id) {
      props.previewImage(id);
    }
  }

  return (
    <div>
      <div className="row m-2">
        {repairImages.map((img) => (
          <div
            key={img.filename}
            className="card bg-light mr-3 mb-3"
            style={{ width: "160px" }}
          >
            <button
              className="btn btn-outline-secondary my-2 p-3"
              onClick={(e) => previewImage(e, img.filename)}
            >
              <PiImagesSquareDuotone fontSize={48} />
            </button>
            <div className="card-body">
              <div className="card-text">{img.title}</div>
            </div>
            <div className="mt-1 mb-2 mr-1 d-flex justify-content-end">
              <a
                href="#"
                className="text-decoration-none text-danger ml-5"
                onClick={(e) => deleteImage(e, img.filename)}
              >
                <i className="bi bi-trash mr-1"></i>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RepairImageList;
