import React, { useEffect } from "react";
import { BiLogIn } from "react-icons/bi";
import { BsPencilSquare } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import { fetchUsers } from "../../../services/userApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import UserCreateDialog from "./UserCreateDialog";
import UserEditDialog from "./UserEditDialog";
import { IUser } from "./types";
import { formatErrorMessage } from "../../../common/utils";

const UsersEdit: React.FC = () => {
  const [users, setUsers] = React.useState<any[]>([]);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([]);
  const [sortDirection, setSortDirection] = React.useState(1);
  const [inputValue, setInputValue] = React.useState("");

  const { data, isError, error } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
    staleTime: Infinity,
  });

  useEffect(() => {
    setUsers(data?.data || []);
  }, [data]);

  useEffect(() => {
    handleFindUser();
  }, [inputValue, users]);

  function handleLoginAsUser(e: any, id: any): void {
    e.preventDefault();
    //href={`/users/${user.id}/login`}
    console.log("Login as user", id);
  }

  function handleEditUser(e: any, user: any): void {
    e.preventDefault();
    setSelectedUser(user);
    setShowEditModal(true);
    console.log("Edit user", user.id);
  }

  function handleCreateUser(e: any): void {
    e.preventDefault();
    setShowCreateModal(true);
    console.log("Create user");
  }

  function handleModalClose(): void {
    setShowCreateModal(false);
    setShowEditModal(false);
  }

  function updateUsersList(user: IUser): void {
    if (!user?.id) return;
    const updatedUsers = users.map((u) => {
      if (u.id === user.id) {
        return user;
      }
      return u;
    });
    setUsers(updatedUsers);
  }

  function addUsersListItem(user: IUser): void {
    if (!user?.id) return;
    const updatedUsers = [...users, user];
    const sortedUsers = updatedUsers.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setUsers(sortedUsers);
  }

  function handleFindUser(): void {
    const value = inputValue?.toLowerCase() || "";
    if (value.length > 0) {
      const filteredUsers = users.filter((u) =>
        u.name.toLowerCase().includes(value)
      );
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredUsers(users);
    }
  }

  function handleSort(e: any): void {
    e.preventDefault();
    const id = e.target.id;
    const sortedUsers = [...filteredUsers];
    const sortDirectionOpposite = sortDirection * -1;
    if (id == "role") {
      sortedUsers.sort((a, b) =>
        a.role.name > b.role.name ? sortDirection : sortDirectionOpposite
      );
    } else {
      sortedUsers.sort((a, b) =>
        a[id] > b[id] ? sortDirection : sortDirectionOpposite
      );
    }
    setSortDirection(sortDirectionOpposite);
    setFilteredUsers(sortedUsers);
  }

  function handleInputChange(event: any): void {
    const value = event.target.value;
    setInputValue(value);
  }

  function clearInputValue(): void {
    setInputValue("");
  }

  if (isError) {
    return (
      <div className="m-1">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{formatErrorMessage(error.message)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <UserCreateDialog
        show={showCreateModal}
        onHide={handleModalClose}
        updateUsersList={addUsersListItem}
      />
      <UserEditDialog
        show={showEditModal}
        onHide={handleModalClose}
        updateUsersList={updateUsersList}
        user={selectedUser}
      />
      <ToastContainer />
      <BreadcrumbsComponent
        links={[{ to: "/settings", label: "Settings" }]}
        leaf="Users"
      />
      <div>
        <div className="page-title">Users</div>
        <p className="px-3">
          <small>
            <i>
              <b>NOTE:</b> The password for a user can only be set when the user
              is created. If you need to reset a password, you can do so by
              clicking the "Update" button and then clicking the "Save" button.
              This will generate a new password for the user and copy it to your
              clipboard. You can then paste the password into an email or other
              communication to the user.
            </i>
          </small>
        </p>
      </div>
      <div className="row px-3">
        <div className="d-flex justify-content-end mr-5">
          {/* <TextField
            label="Input field"
            value={inputValue}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {inputValue && <ThreeDRotation />}
                </InputAdornment>
              ),
            }}
          /> */}
          <input
            type="text"
            placeholder="Find User"
            className="form-control w-25 mr-2"
            onChange={handleInputChange}
          />
          <button className="btn btn-primary mr-5" onClick={handleCreateUser}>
            Add User
          </button>
        </div>
      </div>
      <div className="row p-5 pt-3">
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th>
                <a
                  href="#"
                  id="name"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Name
                </a>
              </th>
              <th>
                <a
                  href="#"
                  id="email"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Email
                </a>
              </th>
              <th>
                <a
                  href="#"
                  id="role"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Permission
                </a>
              </th>
              <th>Update</th>
              <th>Login</th>
              <th>
                <a
                  href="#"
                  id="active"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Active
                </a>
              </th>
            </tr>
          </thead>
          {/* @if($my_rank = Auth::user()->role['rank'])@endif  */}
          {/* @if($disabled = $my_rank >= $user->role['rank']? '': 'disabled')@endif */}
          <tbody>
            {filteredUsers &&
              filteredUsers.map((user: any) => (
                <tr key={user.id}>
                  <th scope="row">{user.name}</th>
                  <td>{user.email}</td>
                  <td>{user.role.name}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => handleEditUser(e, user)}
                      className="btn btn-default"
                    >
                      <BsPencilSquare />
                    </a>
                  </td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => handleLoginAsUser(e, user.id)}
                      className="btn btn-default"
                    >
                      <BiLogIn />
                    </a>
                  </td>
                  <td>
                    <input type="checkbox" checked={user.active} readOnly />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersEdit;
