import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { showToaster } from "../../../services/toastService";
import { AddBox } from "@mui/icons-material";
import PricesCreateDialog from "./PricesCreateDialog";
import PricesList from "./PricesList";
import { fetchPrices } from "../../../services/pricesApiService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import CustomTabPanel from "../../common/CustomerTabPanel";
import OtherPricesList from "./OtherPricesList";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { useQuery } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";

const PricesEdit: React.FC = () => {
  const [prices, setPrices] = useState({} as any);
  const [showModal, setShowModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const typeDeposit = "deposit";
  const typeOthers = "others";

  const { data, isError, error } = useQuery({
    queryKey: ["prices"],
    queryFn: () => fetchPrices(),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isError) {
      showToaster(
        "Error fetching Prices " + formatErrorMessage(error.message),
        "error"
      );
    }
    setPrices(data?.data || []);
  }, [data, error]);

  function handleCreate(event: any): void {
    event.preventDefault();
    setShowModal(true);
  }

  function handleOnSaved(price: any, type: string): void {
    console.log("price saved ", price);
    const newPrices = { ...prices };
    newPrices[type].push(price);
    setPrices(newPrices);
  }

  function handleOnListUpdated(list: any[], type: string): void {
    const newPrices = { ...prices };
    newPrices[type] = list;
    setPrices(newPrices);
  }

  function handleChange(event: any, value: any): void {
    setTabValue(value);
  }

  return (
    <>
      <ToastContainer />
      <PricesCreateDialog
        price={null}
        type={tabValue === 0 ? typeDeposit : typeOthers}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div className="container">
        <BreadcrumbsComponent
          links={[{ to: "/settings", label: "Settings" }]}
          leaf="Prices"
        />
        <div className="page-title">Prices</div>
        <div className="row">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs"
            >
              <Tab label="Pallet Deposit" />
              <Tab label="Others" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <div className="row">
              <div className="row px-3 mt-3">
                <div className="col d-flex justify-content-end">
                  <button
                    className="btn btn-primary mr-5"
                    onClick={handleCreate}
                  >
                    <AddBox className="mr-2" />
                    Add New Price
                  </button>
                </div>
              </div>
              <div>
                <PricesList
                  prices={prices[typeDeposit]}
                  type={typeDeposit}
                  onListUpdated={handleOnListUpdated}
                />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <div className="row">
              <div className="row px-3 mt-3">
                <div className="col d-flex justify-content-end">
                  <button
                    className="btn btn-primary mr-5"
                    onClick={handleCreate}
                  >
                    <AddBox className="mr-2" />
                    Add New Item
                  </button>
                </div>
              </div>
              <div>
                <OtherPricesList prices={prices["others"]} type={typeOthers} />
              </div>
            </div>
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default PricesEdit;
