import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { adaptiveCurrencyFormat } from "../../common/utils";
import { IGoals, IState } from "./types";
import { getAmountUnderGoal } from "./goalsService";

interface IProps {
  goals: IGoals;
  jobsSold: any[];
}

const GoalsDetails = (props: IProps) => {
  const { goals, jobsSold } = props;
  const [state, setState] = useState<IState>({} as IState);

  useEffect(() => {
    calculateGoals();
  }, [goals]);

  const calculateGoals = () => {
    const soldJobsNeeded =
      Math.round(goals.goalAmount / goals.avgJobAmount) || 0;
    const totalLeadsNeeded = Math.round(
      soldJobsNeeded / (goals.percentJobsSold / 100) || 0
    );
    const _state = {
      soldJobsNeeded,
      totalLeadsNeeded,
      totalLeadsNeededWeek: Math.round(totalLeadsNeeded / 52),
      totalLeadsNeededMonth: Math.round((totalLeadsNeeded / 52) * 4),
      avgJobsNeededMonth: Math.round(soldJobsNeeded / 12),
      amountUnderGoal: getAmountUnderGoal(jobsSold, goals),
    };
    setState(_state);
  };

  return (
    <div className="">
      <div className="row m-3">
        <Paper elevation={3} className="mr-3 mt-1 goal-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Goal Amount</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(goals.goalAmount)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="goal-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Average Job Amount</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(goals.avgJobAmount)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="goal-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Percentage of Jobs Sold</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(goals.percentJobsSold)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="goal-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Number of Reps</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(goals.numberReps)}
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <div className="row m-3">
        <Paper elevation={3} className="goal-card-medium">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Total Leads Needed</div>
            </div>
            <div className="col">
              <div className="goal-card-amount">
                {adaptiveCurrencyFormat(state.totalLeadsNeeded)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="goal-card-subtitle">Per Week</div>
            </div>
            <div className="col">
              <div className="goal-card-sub-amount">
                {adaptiveCurrencyFormat(state.totalLeadsNeededWeek)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="goal-card-subtitle">Per Month</div>
            </div>
            <div className="col">
              <div className="goal-card-sub-amount">
                {adaptiveCurrencyFormat(state.totalLeadsNeededMonth)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="goal-card-medium">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Sold Jobs Needed</div>
            </div>
            <div className="col">
              <div className="goal-card-amount">
                {adaptiveCurrencyFormat(state.soldJobsNeeded)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="goal-card-subtitle">Per Month</div>
            </div>
            <div className="col">
              <div className="goal-card-sub-amount">
                {adaptiveCurrencyFormat(state.avgJobsNeededMonth)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper elevation={3} className="goal-card-small">
          <div className="dashboard-card-body row">
            <div className="col">
              <div className="card-title">Amount Under</div>
            </div>
            <div className="col">
              <div className="card-amount">
                {adaptiveCurrencyFormat(state.amountUnderGoal)}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default GoalsDetails;
